import * as React from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ModalBackdrop from "../common/ModalBackdrop";
import PrimaryButton from "../common/PrimaryButton";
import { HAS_STARTED_A_SURVEY, YOUR_SURVEY_ID } from "../../data/Constants";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItems = ({ toggle, isOpen }: any) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const handleLogout = (): void => {
    instance.logoutRedirect().catch((err) => console.log(err.message));
  };
  const style = { display: isOpen ? "flex" : "none" };
  
  const hasStartedSurvey = window.localStorage.getItem(HAS_STARTED_A_SURVEY);
  const links: { link: string; text: string }[] = [
    {
      link: "/",
      text: "Home",
    },
    {
      link: "/surveyList",
      text: "Reports",
    },
    {
      link: "/information",
      text: "Information",
    },
    {
      link: "exit",
      text: "Quit survey",
    },
    {
      link: "signout",
      text: "Sign out",
    },
  ];
  const [exitSurvey, setExitSurvey] = useState(false);
  const navigate = useNavigate();

 const linksToShow = hasStartedSurvey ? links : links.filter((entry) => entry.link !== "exit")

  return (
    <>
      {exitSurvey && (
        <ModalBackdrop
          onClick={() => {
            setExitSurvey(false);
          }}
        >
          <div className="flex flex-col items-center gap-4 rounded-md bg-white p-6">
            <p>Are you sure you want to quit, all of your data will be lost!</p>
            <PrimaryButton
              fontSize="text-base"
              onClick={() => {
                window.localStorage.removeItem(HAS_STARTED_A_SURVEY);
                window.localStorage.removeItem(YOUR_SURVEY_ID);
                navigate("/");
                setExitSurvey(false);
              }}
              text="Quit survey"
              className="bg-red-800 text-white hover:bg-red-600 "
            />
          </div>
        </ModalBackdrop>
      )}
      {linksToShow.map((linkObj) => (
        <motion.li
          key={linkObj.link}
          onClick={toggle}
          className="flex w-[200px] cursor-pointer items-center rounded-md border-2 border-blue2"
          variants={variants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          {!linkObj.link.includes("/") ? (
            <div
              className="w-full px-2 py-0.5"
              style={style}
              onClick={() => {
                linkObj.link === "exit" ? setExitSurvey(true) : handleLogout();
              }}
            >
              {linkObj.text}
            </div>
          ) : (
            <Link to={linkObj.link} className="px-2 py-0.5 no-underline" style={style}>
              {linkObj.text}
            </Link>
          )}
        </motion.li>
      ))}
    </>
  );
};

export default MenuItems;
