import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getProfileImage } from "../../api/ApiService";
import { AFRY_Logotype_Horizontal_Black } from "../../assets/index";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import { ADMIN_GROUP, HAS_STARTED_A_SURVEY, YOUR_SURVEY_ID } from "../../data/Constants";
import { HamburgerMenu } from "../HamburgerMenu/HamburgerMenu";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import "./Header.css";
import PrimaryButton from "../common/PrimaryButton";
import ModalBackdrop from "../common/ModalBackdrop";

interface IImage {
  src: string;
}

const Header: React.FC = () => {
  const { width } = useWindowDimensions();
  const { instance, accounts } = useMsal();
  const [imageUrl, setImageUrl] = useState<IImage>({ src: "" });
  const [visible, setVisibilty] = useState(false);
  const navigate = useNavigate();

  const account = accounts[0];
  instance.setActiveAccount(account);

  const isMobile = width < 1100;

  useEffect(() => {
    if (isMobile && visible) {
      setVisibilty(!visible);
    }
  }, [width]);

  useEffect(() => {
    if (instance.getActiveAccount() !== null && instance.getActiveAccount() !== undefined) {
      const imageAsBlob = getProfileImage();

      imageAsBlob.then((response) => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(response?.data);
        setImageUrl({ src: blobUrl });
      });
    }
  }, [account]);

  const handleLogout = (): void => {
    instance.logoutRedirect().catch((err) => console.log(err.message));
  };

  const name = accounts[0] && accounts[0].name;

  const imageComponent = () => {
    return imageUrl.src !== "" && account ? (
      <img src={imageUrl.src} alt={account.name} width={50} height={50} className={"image"} />
    ) : null;
  };

  const handleVisibility = () => {
    setVisibilty(false);
  };

  const [homeLink, setHomeLink] = useState<string>("/");

  useEffect(() => {
    const prevDataEncrypted = window.localStorage.getItem(HAS_STARTED_A_SURVEY) || null;
    setHomeLink(prevDataEncrypted ? "/survey" : "/");
  }, []);

  const [exitSurvey, setExitSurvey] = useState(false);

  const hasStartedSurvey = window.localStorage.getItem(HAS_STARTED_A_SURVEY);
  return (
    <header className={isMobile ? "burgerHeader" : "header"}>
      {exitSurvey && (
        <ModalBackdrop
          onClick={() => {
            setExitSurvey(false);
            setVisibilty(false);
          }}
        >
          <div className="flex flex-col items-center gap-4 rounded-md bg-white p-6">
            <p>Are you sure you want to quit, all of your data will be lost!</p>
            <PrimaryButton
              fontSize="text-base"
              onClick={() => {
                window.localStorage.removeItem(HAS_STARTED_A_SURVEY);
                window.localStorage.removeItem(YOUR_SURVEY_ID);
                navigate("/");
                setExitSurvey(false);
              }}
              text="Quit survey"
              className="bg-red-800 text-white hover:bg-red-600 "
            />
          </div>
        </ModalBackdrop>
      )}

      <div className={"headerContainer"}>
        <div className={isMobile ? "BurgerBrand" : "brand"}>
          <img src={AFRY_Logotype_Horizontal_Black} width={150} height={42} alt="Afry logo" />
        </div>
        {isMobile ? (
          <>
            <HamburgerMenu />
          </>
        ) : (
          <>
            <Link to={homeLink} className={"HeaderTitle"} onClick={handleVisibility}>
              Sustainability Reflection Tool
            </Link>
            <div className={"rightCorner"}>
              {/* <ProtectedRoute isForLink={true} validAzureAdGroupId={ADMIN_GROUP}>
                <Link className={"HeaderLink"} to="/formBuilder" onClick={handleVisibility}>
                  {t("header", { context: "formBuilder" })}
                </Link>
              </ProtectedRoute> */}
              <ProtectedRoute isForLink={true} validAzureAdGroupId={ADMIN_GROUP}>
                <Link className={"HeaderLink"} to="/surveyList" onClick={handleVisibility}>
                  Reports
                </Link>
              </ProtectedRoute>
              <Link className={"HeaderLink"} to="/information" onClick={handleVisibility}>
                Information
              </Link>
              <div
                className={` z-20 flex flex-col items-center justify-center gap-8 rounded-md bg-white px-2  ${
                  visible ? ` ${hasStartedSurvey ? "mt-[136px]" : "mt-[76px]"} py-4 shadow-md` : ""
                }`}
              >
                <div
                  onClick={() => {
                    setVisibilty(!visible);
                  }}
                  className={"flex cursor-pointer items-center justify-end"}
                >
                  {imageComponent()}
                  <p className={"pl-2 text-base text-grey1"}>{name}</p>
                </div>
                {visible ? (
                  <div className="flex flex-col gap-4">
                    {hasStartedSurvey && (
                      <PrimaryButton
                        isSecondary
                        fontSize="text-base"
                        onClick={() => {
                          setExitSurvey(true);
                        }}
                        text="Quit Survey"
                      />
                    )}
                    <PrimaryButton
                      onClick={handleLogout}
                      fontSize="text-base"
                      isSecondary
                      text="Sign out"
                      className=""
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
