import { BrowserCacheLocation, PopupRequest, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser/dist/config/Configuration";

const redirectLink =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_LOCAL_URL;

const appRegId =
  process.env.REACT_APP_APP_REG_ID !== undefined
    ? process.env.REACT_APP_APP_REG_ID
    : "e5091e1c-d316-48aa-a615-e2380fc3c7eb";
export const msalConfig: Configuration = {
  auth: {
    clientId: appRegId,
    authority: "https://login.microsoftonline.com/58af3eba-510e-4544-8cfd-85f5e0206382",
    redirectUri: redirectLink,
    postLogoutRedirectUri: redirectLink,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

export const authRequest: RedirectRequest = {
  scopes: [appRegId + "/.default"],
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
