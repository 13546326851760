import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

interface IProvider {
  children: ReactNode;
}

export const BASE_LOCALE = {
  da: "",
  default: "",
  fi: "",
  de: "",
  no: "",
  sv: "",
  en: "",
  id: "",
};

export const SurveyContext = createContext<IContextValues>({
  value: {
    formVersion: 0,
    formPublishDate: "",
    taxonomy: {},
    environment: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    social: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    socioEconomic: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    influence: {},
    introduction: {
      aboutProject: {},
      aboutSRT: {},
      exampleOfEvaluation: {},
      exampleOfResult: {},
      sustainabilityAtAfry: {},
      sustainableDevelopmentGoals: {},
    },
    utils: { questionChoices: {}, sectionReflection: {} },
  },
  locale: "en",
  locals: [],
  recommendations: [],
  updateRecommendations: () => undefined,
  updateLocales: () => undefined,
  updateLocale: () => undefined,
  addSurveyPages: () => undefined,
});

export const useAppContext = () => {
  return useContext(SurveyContext);
};

const SurveyContextProvider: React.FC<IProvider> = ({ children }) => {
  const [surveyPages, setSurveyPages] = useState<ISection>({
    formVersion: 0,
    formPublishDate: "",
    taxonomy: {},
    environment: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    social: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    socioEconomic: {
      headerText: { text: { ...BASE_LOCALE }, name: "" },
      impactAreas: [],
      other: { id: "", name: "", title: { ...BASE_LOCALE } },
    },
    influence: {},
    introduction: {
      aboutProject: {},
      aboutSRT: {},
      exampleOfEvaluation: {},
      exampleOfResult: {},
      sustainabilityAtAfry: {},
      sustainableDevelopmentGoals: {},
    },
    utils: { questionChoices: {}, sectionReflection: {} },
  });
  const [language, setLanguage] = useState<string>("en");
  const [appLocales, setAppLocales] = useState<ILocale[]>([]);
  const [recs, setRecs] = useState<ILocale[]>([]);

  const addPages: (data: ISection) => void = (data: ISection) => {
    setSurveyPages(data);
  };

  const handleLocale = (newLocale: string) => {
    setLanguage(newLocale);
  };

  const handleAppLocales = (newLocales: ILocale[]) => {
    setAppLocales(newLocales);
  };

  const handleRecs = (newRecs: ILocale[]) => {
    setRecs(newRecs);
  };

  const memo = useMemo<IContextValues>(
    () => ({
      value: surveyPages,
      addSurveyPages: addPages,
      locale: language,
      locals: appLocales,
      recommendations: recs,
      updateRecommendations: handleRecs,
      updateLocales: handleAppLocales,
      updateLocale: handleLocale,
    }),
    [surveyPages, addPages, language, handleLocale, appLocales, handleAppLocales, recs, handleRecs]
  );

  return <SurveyContext.Provider value={memo}>{children}</SurveyContext.Provider>;
};

export default SurveyContextProvider;
