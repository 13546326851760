import React from "react";
import { useTranslation } from "react-i18next";
import URL from "../components/common/URL";
import { handleFileChange } from "../utils/FormBuilderUtils";
import  QUESTIONNAIRE_JSON from "../assets/Questionnaire.json";
import  Recomendations_JSON from "../assets/recommendationsV2.json";

const InfoPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-6 p-8 text-base tracking-wide">
      {/* <input type="file" onChange={(e: any) => handleFileChange(e, QUESTIONNAIRE_JSON, Recomendations_JSON)} /> */}
      <p className=" max-w-[600px] ">
        {t("infoPage", { context: "part1Start" })}{" "}
        <URL
          base="url"
          context="sustainableSolutionsKnowledgeCenterLink"
          text="sustainableSolutionsKnowledgeCenterText"
        />
        {t("infoPage", { context: "part1End" })}
      </p>
      <p className="max-w-[600px]">
        {t("infoPage", { context: "part2Start" })}
        <URL base="url" context="feedbackLink" text="feedbackText" />
        {t("infoPage", { context: "part2End" })}
      </p>
    </div>
  );
};

export default InfoPage;
