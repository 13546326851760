import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/SurveyContextProvider";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import { findNestedObj } from "../CreateAnswerObject";
import SymbolLegend from "../common/SymbolLegend";
import styles from "./GapList.module.css";
import { selectedLanguage } from "../../Localization/Localization";

interface IGap {
  title: string;
  category: string;
}

interface IGapListProps {
  surveyData: any;
  initialVisibility: string;
  children?: any;
}

export const updateGapList = (surveyData: any, setGapList: any) => {
  const tempList: string[] = [];
  Object.entries(surveyData).forEach((item: any) => {
    if (item[0].includes("_managed") && (!item[1] || typeof item[1] === "string")) {
      tempList.push(item[0]);
    }
  });
  setGapList(tempList);
};

export const cleanTitle = (title: string): string => {
  let tempTitle: string = title;
  if (tempTitle.includes("*")) {
    tempTitle = tempTitle.split("*")[0];
  }
  if (tempTitle.includes(":")) {
    tempTitle = tempTitle.split(":")[0];
  }
  return tempTitle;
};

const GapList: React.FC<IGapListProps> = ({ surveyData, initialVisibility, children }) => {
  const { width } = useWindowDimensions();
  const [gapList, setGapList] = useState<string[]>([]);
  const context = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    updateGapList(surveyData, setGapList);
  }, [surveyData.pageNo]);

  const listToDisplay: IGap[] = [];
  if (gapList.length !== 0) {
    gapList.forEach((gap: string) => {
      gap = gap.split("_managed")[0];
      const category = gap.split("_")[0];
      const questionId = gap.split("_")[1];
      const questionObj: IValue = findNestedObj(context.value, "id", questionId);

      listToDisplay.push({ title: cleanTitle(questionObj.subject[selectedLanguage]), category: category });
    });
  }

  const noContent = () => {
    return (
      <div className={styles.noContent}>
        <p>{t("gapList", { context: "empty" })}</p>
      </div>
    );
  };

  const isShowingOnResultScreen = initialVisibility === "flex";
  return (
    <div
      id="gapList"
      style={{
        width: width < 950 ? "100%" : isShowingOnResultScreen ? "100%" : "50%",
        display: initialVisibility,
      }}
      className={styles.gapList}
    >
      <h4 style={{ display: isShowingOnResultScreen ? "none" : "block" }} className={styles.gapHeader}>
        {t("gapList", { context: "notEmpty" })}
      </h4>
      {listToDisplay.length > 0 ? (
        <>
          {children}
          <div id="legends" style={{ flexDirection: width < 950 ? "column" : "row" }} className={styles.legends}>
            <div className={styles.legend}>
              <SymbolLegend typeOfSymbol={"Environment"} />
              <p>{t("symbols", { context: "env" })}</p>
            </div>
            <div className={styles.legend}>
              <SymbolLegend typeOfSymbol={"Social"} />
              <p>{t("symbols", { context: "social" })}</p>
            </div>
            <div className={styles.legend}>
              <SymbolLegend typeOfSymbol={"SocioEconomic"} />
              <p>{t("symbols", { context: "socio" })}</p>
            </div>
          </div>
          {listToDisplay
            .sort((a, b) => a.category.localeCompare(b.category))
            .map((gap: IGap, i: number) => (
              <div key={gap.title + i} className={styles.legend}>
                <SymbolLegend typeOfSymbol={gap.category} />
                <p>{gap.title}</p>
              </div>
            ))}
        </>
      ) : (
        noContent()
      )}
    </div>
  );
};

export default GapList;
