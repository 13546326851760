import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGroups } from "../../api/ApiService";
import { authRequest } from "../../auth/msalConfig";

interface IMsalGroupProps {
  children: any;
  validAzureAdGroupId: string;
  isForLink: boolean;
}

const ProtectedRoute: React.FC<IMsalGroupProps> = ({ children, validAzureAdGroupId, isForLink }) => {
  const { instance } = useMsal();
  const [authorized, setAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const checkIfValidGroupUser = async () => {
      setIsChecking(true);
      const response = await instance.acquireTokenSilent({
        ...authRequest,
        account: instance.getAllAccounts()[0],
      });

      const groups: string[] = await (await getGroups()).data.value;

      if (!response || !response.idTokenClaims) {
        setAuthorized(false);
      } else {
        setAuthorized(groups.includes(validAzureAdGroupId));
      }
      setIsChecking(false);
    };

    checkIfValidGroupUser();
  }, [instance]);

  return <> {authorized ? <>{children}</> : isChecking ? null : isForLink ? null : <>{t("unauthorized")}</>}</>;
};

export default ProtectedRoute;
