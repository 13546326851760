import React from "react";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import "./index.css"
import "./Localization/Localization";
import { msalInstance } from "./auth/msalConfig";
import AuthenticatedNavigation from "./pages/AuthenticatedNavigation";
import LoginPage from "./pages/LoginPage";
import SurveyContextProvider from "./context/SurveyContextProvider";

const App:React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <SurveyContextProvider>
          <AuthenticatedNavigation />
        </SurveyContextProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
