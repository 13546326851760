import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedLanguage } from "../../Localization/Localization";
import { useAppContext } from "../../context/SurveyContextProvider";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import imagesPool from "../../data/imagesPool";
import { findNestedObj } from "../CreateAnswerObject";
import { updateGapList } from "../GapList/GapList";
import GapListResult from "../GapListResult/GapListResult";
import SymbolLegend from "../common/SymbolLegend";
import styles from "./CompletedContent.module.css";

interface IResultProps {
  date: string;
  surveyResult: ISurveyResult;
  answers: any;
  allRecommendations: any[];
}

const Result = React.forwardRef((_props: any, ref: any) => {
  const { answers, date, surveyResult, allRecommendations } = _props._props as IResultProps;

  const { t } = useTranslation();
  const context = useAppContext();

  const renderImageList = (images: number[]) => {
    images.sort((a: number, b: number) => a - b);
    return images.map((num: number) => {
      return (
        <img
          src={imagesPool[num - 1].src}
          height={"90px"}
          width={"90px"}
          style={{ paddingRight: 8, paddingBottom: 2 }}
          className={styles.image}
          key={imagesPool[num - 1].src}
          alt={`goal ${num}`}
          id={"image"}
        />
      );
    });
  };

  const findRecommendations = () => {
    const recs: any[] = [];
    surveyResult.recommendations.map((recId: string) => {
      Object.entries(allRecommendations).forEach((entry) => {
        if (entry[0] === "addons") {
          entry[1].forEach((nestedRec: any) => {
            if (nestedRec.recommendation.id === recId) {
              recs.push(nestedRec.recommendation);
            }
          });
        } else {
          if (entry[1].id === recId) {
            recs.push(entry[1]);
          }
        }
      });
    });
    return recs;
  };

  const langToIndex = selectedLanguage === "en" ? "default" : selectedLanguage;
  let macroTitle = "";
  if (surveyResult.macroSector !== 0) {
    const macroSector = context.value.taxonomy.macroSectors[surveyResult.macroSector];
    if (macroSector && macroSector.title) {
      const newTitle: string = macroSector.title[langToIndex].split(". ")[1];
      macroTitle = newTitle ? newTitle.toLowerCase() : macroSector.title[langToIndex];
    }
  }

  const recommendationsList = findRecommendations().map((recommendation: any) => {
    if (recommendation.url) {
      return (
        <li className={styles.link} key={recommendation.id}>
          {recommendation.sectorHeader && macroTitle !== "" && recommendation.sectorHeader[langToIndex] + macroTitle}
          {recommendation.start[langToIndex]}
          <a href={recommendation.url.link} target="_blank" rel="noreferrer">
            {recommendation.url[langToIndex]}
          </a>
          {recommendation.end && recommendation.end[langToIndex]}
        </li>
      );
    }
    return (
      <li className={styles.marker} key={recommendation.id}>
        {recommendation[selectedLanguage]}
      </li>
    );
  });

  const noContent = (type: string) => {
    return (
      <div className={styles.noContent}>
        <p>
          {t("result", { context: "noWithSpace" })}
          {t(type)} {t("result", { context: "identified" })}.
        </p>
      </div>
    );
  };

  const pdfContentRow = (headline: string, pageNumber: string) => (
    <li
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <span style={{ paddingRight: 6, whiteSpace: "nowrap", width: "fit-content" }}>{headline}</span>
      <span style={{ width: "max-content", overflow: "hidden" }}>{dottedLine}</span>
      <span style={{ width: "fit-content", paddingLeft: 6, textAlign: "end" }}>{pageNumber}</span>
    </li>
  );

  const pdfContents = () => {
    return (
      <div id="pdfcontents" className={styles.pdfContents}>
        <h4 style={{ marginBlockEnd: 0 }}>{t("pdf", { context: "contents" })}</h4>
        <ul
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingInline: 0,
          }}
        >
          {pdfContentRow(t("pdfHeadlines", { context: "recommendations" }), "2")}
          {pdfContentRow(t("pdfHeadlines", { context: "negative" }), "3")}
          {pdfContentRow(t("pdfHeadlines", { context: "positive" }), "3")}
          {pdfContentRow(t("pdfHeadlines", { context: "potential" }), "4")}
        </ul>
      </div>
    );
  };

  const { width } = useWindowDimensions();

  const [initialGapList, setInitialGapList] = useState<any[]>([]);

  useEffect(() => {
    updateGapList(surveyResult.rawData, setInitialGapList);
  }, []);

  const gapList1: any[] = [];
  const gapList2: any[] = [];
  const gapList3: any[] = [];
  const gapList4: any[] = [];
  const gapList5: any[] = [];

  const isBetween = (index: number, start: number, end: number) => {
    return index >= start && index <= end ? true : false;
  };

  initialGapList.forEach((gap: string, index: number) => {
    gap = gap.split("_managed")[0];
    const category = gap.split("_")[0];
    const questionId = gap.split("_")[1];

    const questionObj: IValue = findNestedObj(context.value, "id", questionId);

    if (isBetween(index, 0, 20)) {
      gapList1.push({ title: questionObj.subject, category: category });
    } else if (isBetween(index, 21, 46)) {
      gapList2.push({ title: questionObj.subject, category: category });
    } else if (isBetween(index, 47, 72)) {
      gapList3.push({ title: questionObj.subject, category: category });
    } else if (isBetween(index, 73, 98)) {
      gapList4.push({ title: questionObj.subject, category: category });
    } else {
      gapList5.push({ title: questionObj.subject, category: category });
    }
  });

  const dottedLine =
    ".........................................................." +
    "..............................................................................." +
    "................................................................................";

  const newPageDivider = (pageNumber: string) => {
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <div id={"page" + pageNumber} className={styles.pageContainer}>
          <p className={styles.pageNumber}>
            {t("pdf", { context: "page" })} {pageNumber}
          </p>
        </div>
      </>
    );
  };

  return (
    <div ref={ref} className="m-2 w-fit max-w-[800px] rounded-2xl bg-white p-8 shadow-md" id="result">
      <div className={styles.pdfImageContainer} id="header">
        <div className={styles.innerContainer}>
          <img
            src="/AFRY_Logotype_Horizontal_Explainer.png"
            className={styles.pdfImage}
            key="/AFRY_Logotype_Horizontal_Explainer.png"
            alt="Afry ÅF Pöyry"
          />
          <p className={styles.pageNumberInitial}>{t("pdf", { context: "page" })} 1</p>
        </div>
      </div>
      <div id="body1">
        <h3 className="mb-4 text-[28px] font-semibold" style={{ fontFamily: "Campton-Light" }}>
          {t("result_header")}
        </h3>
        <div className={styles.projectInfo}>
          <div className={styles.infoRow}>
            {t("client")}: <p>{answers.projectInfo.client ? answers.projectInfo.client : "N/A"}</p>
          </div>
          <div className={styles.infoRow}>
            {t("project")}: <p>{answers.projectInfo.objectType ? answers.projectInfo.objectType : "N/A"}</p>
          </div>
          <div className={styles.infoRow}>
            {t("date")}: <p>{date ? date : "N/A"}</p>
          </div>
        </div>
        {pdfContents()}
      </div>
      {newPageDivider("2")}
      <div id="body2">
        <h4 className="mb-2 mt-4 text-[20px] font-medium">{t("result_recommendation")}</h4>
        {surveyResult.recommendations.length !== 0 ? (
          <div className="ml-6">
            <ul id="recommendations" style={{ margin: 0 }}>
              {recommendationsList}
            </ul>
          </div>
        ) : (
          noContent("result_recommendation")
        )}
      </div>
      {newPageDivider("3")}
      <div id="body3">
        <h4 className="mb-2 mt-4 text-[20px] font-medium">{t("result_relevance")}</h4>
        <p className={styles.subHeader}>{t("result_negative")}</p>
        {surveyResult.negativeRisks.length === 0 ? (
          noContent("result_noNegative")
        ) : (
          <div className="flex flex-row flex-wrap">{renderImageList(surveyResult.negativeRisks)}</div>
        )}
        <p className={styles.subHeader}>{t("result_positive")}</p>
        {surveyResult.positivePotentials.length === 0 ? (
          noContent("result_noPositive")
        ) : (
          <div className="flex flex-row flex-wrap">{renderImageList(surveyResult.positivePotentials)}</div>
        )}
      </div>
      {newPageDivider("4")}
      <div id="body4">
        <h4 className="mb-2 mt-4 text-[20px] font-medium">{t("result_identifiedGAPsHeader")}</h4>

        {gapList1.length !== 0 ? (
          <>
            <p className="mb-3">{t("result_identifiedGAPs")}</p>
            <div id="legends" style={{ flexDirection: width < 950 ? "column" : "row" }} className={styles.legends}>
              <div className={styles.legend}>
                <SymbolLegend typeOfSymbol="Environment" />
                <p>{t("symbols_env")}</p>
              </div>
              <div className={styles.legend}>
                <SymbolLegend typeOfSymbol="Social" />
                <p>{t("symbols_social")}</p>
              </div>
              <div className={styles.legend}>
                <SymbolLegend typeOfSymbol="SocioEconomic" />
                <p>{t("symbols_socio")}</p>
              </div>
            </div>
          </>
        ) : (
          noContent("result_aspects")
        )}
        {gapList1.length !== 0 && <GapListResult gapList={gapList1} />}
      </div>
      {gapList2.length !== 0 && newPageDivider("5")}
      <div id="body5">{gapList2.length !== 0 && <GapListResult gapList={gapList2} />}</div>
      {gapList3.length !== 0 && newPageDivider("6")}
      <div id="body6"> {gapList3.length !== 0 && <GapListResult gapList={gapList3} />}</div>
      {gapList4.length !== 0 && newPageDivider("7")}
      <div id="body7"> {gapList4.length !== 0 && <GapListResult gapList={gapList4} />}</div>
      {gapList5.length !== 0 && newPageDivider("8")}
      <div id="body8"> {gapList5.length !== 0 && <GapListResult gapList={gapList5} />}</div>
    </div>
  );
});

Result.displayName = "Result";

export default Result;
