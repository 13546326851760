import SHA256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import AES from "crypto-js/aes";
import pad from "crypto-js/pad-pkcs7";
import Utf8 from "crypto-js/enc-utf8";
import CryptoJS from "crypto-js";

const IV = Base64.parse("__start__");
const KEY = SHA256("E4tingBl8mmigFa1ukorv3v3r9d4y"); 

export const EncryptData = (message: string) => {
  let encryptedString: any;
  if (typeof message === "string") {
    message = message.slice();
    encryptedString = AES.encrypt(message, KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: pad,
    });
  } else {
    encryptedString = AES.encrypt(JSON.stringify(message), KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: pad,
    });
  }
  return encryptedString.toString();
};

export const DecryptData = (message: string) => {
  const decrypted = AES.decrypt(message, KEY, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: pad,
  });
  return decrypted.toString(Utf8);
};
