import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import NetworkError from "../NetworkError/NetworkError";
import styles from "./MobileSurveyListItems.module.css";

interface ISurveysMobile {
  surveys: ISurveyListItem[];
  isFetching: boolean;
  isError: boolean;
}

const MobileSurveyListItems: React.FC<ISurveysMobile> = ({ surveys, isFetching, isError }) => {
  const navigate = useNavigate();

  const data =
    surveys.length === 0 ? (
      <div className={styles.noContent}>There are no previously evaluated surveys.</div>
    ) : (
      surveys.map((survey: ISurveyListItem) => {
        const goToSurvey = () => {
          navigate("/complete", { state: { id: survey.id } });
        };

        return (
          <div className={styles.listItemHeaderMobile} key={survey.id} onClick={goToSurvey}>
            <p className={styles.cellHeaderMobile}>
              Project: {survey.objectType !== "" ? survey.objectType : "N/A"}
            </p>
            <p className={styles.cellHeaderMobile}>
              Date: {survey.datePerformed !== "" ? survey.datePerformed : "N/A"}
            </p>
            <p className={styles.cellHeaderMobile}>
              Performed by: {survey.performer !== "" ? survey.performer : "N/A"}
            </p>
            <p className={styles.cellHeaderMobile}>
              Client: {survey.client !== "" ? survey.client : "N/A"}
            </p>
          </div>
        );
      })
    );

  return <>{isFetching ? <LoadingSpinner /> : isError ? <NetworkError /> : data}</>;
};

export default MobileSurveyListItems;
