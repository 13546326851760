import { selectedLanguage } from "../../Localization/Localization";

const SustainabilityAtAfry = (sustainabilityAtAfry: any) => {
  const buildHtml = (obj: any) => {
    let htmlString = "";

    const hr = "<hr style=height:5px;visibility:hidden;border:none />";
    const hasTriple = [
      "introduction_sustainabilityAtAfryELearning",
      "introduction_sustainabilityAtAfryManagementSystem",
      "introduction_sustainabilityAtAfryRisk",
    ];
    const contains = (id: string) => {
      return hr.repeat(hasTriple.includes(id) ? 5 : 2);
    };

    Object.values(obj).forEach((val: any, index: number) => {
      if (index > 15) {
        return;
      }

      if (val.link) {
        htmlString +=
          `<a href=${val.link} target=_blank rel=noreferrer >` +
          val[selectedLanguage] +
          `</a> ${contains(val.id)}`;
      } else {
        htmlString += `<${val.type}>` + val[selectedLanguage] + `</${val.type}><br>`;
      }
    });

    const note =
      `<br><p>` +
      obj.sustainabilityAtAfryNoteStart[selectedLanguage] +
      `<a href=${obj.sustainabilityAtAfryNoteLink.link} target=_blank rel=noreferrer >` +
      obj.sustainabilityAtAfryNoteLink[selectedLanguage] +
      "</a> <br>" +
      obj.sustainabilityAtAfryNoteEnd[selectedLanguage] +
      `</p>`;

    htmlString += note;
    return htmlString;
  };

  buildHtml(sustainabilityAtAfry);
  return {
    elements: [
      {
        type: "html",
        html: buildHtml(sustainabilityAtAfry),
      },
      {
        name: "skipIntroduction",
        type: "radiogroup",
        hideNumber: true,
        title: sustainabilityAtAfry.skipIntro,
        choices: [{ value: "Skip", text: sustainabilityAtAfry.skip }],
      },
    ],
  };
};

export default SustainabilityAtAfry;
