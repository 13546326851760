import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../context/SurveyContextProvider";
import { shortLocaleNames, updateSelectedLanguage } from "../../Localization/Localization";

const ChangeLanguageButton: React.FC = () => {
  const { i18n } = useTranslation();
  const context = useAppContext();
  let languages = Object.keys(i18n.store.data).map((val) => val);

  const LANGUAGES_TO_EXCLUDE: string | undefined = process.env.REACT_APP_LANGUAGES_TO_EXCLUDE;
  
  if (LANGUAGES_TO_EXCLUDE) {
    languages = languages.filter((lang) => !LANGUAGES_TO_EXCLUDE.includes(lang));
  }
  const changeLanguage = (lang: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = lang.target.value;
    context.updateLocale(newLanguage);
    i18n.changeLanguage(newLanguage);
    updateSelectedLanguage(newLanguage as keyof ILanguages);
  };

  const getLocaleAsName = (lang: string) => {
    return shortLocaleNames.filter((locale) => locale.value === lang)[0].key;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <label htmlFor="language" style={{}}>
        Select Language
      </label>
      <select
        className="w-[140px] rounded-sm border border-black capitalize"
        name="language"
        id="language"
        value={context.locale}
        onChange={(e) => changeLanguage(e)}
      >
        {languages.map((language: string) => (
          <option key={language} value={language} className="capitalize">
            {getLocaleAsName(language)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChangeLanguageButton;
