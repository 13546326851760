import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import MobileSurveyListItems from "../MobileSurveyListItems/MobileSurveyListItems";
import NetworkError from "../NetworkError/NetworkError";
import styles from "./SurveyListItems.module.css";

interface ISurveys {
  surveys: ISurveyListItem[];
  isFetching: boolean;
  isError: boolean;
}

const SurveyListItems: React.FC<ISurveys> = ({ surveys, isFetching, isError }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const isMobile = width < 800;
  surveys.sort((a, b) => new Date(a.datePerformed).getTime() - new Date(b.datePerformed).getTime());

  const data =
    surveys.length === 0 ? (
      <div className={styles.noContent}>There are no previously evaluated surveys.</div>
    ) : (
      surveys.map((survey: ISurveyListItem) => {
        const goToSurvey = () => {
          navigate("/complete", { state: { id: survey.id } });
        };

        return (
          <div className={styles.listItem} key={survey.id} onClick={goToSurvey}>
            <div className={styles.innerContainer}>
              <p className={styles.cell}>{survey.objectType !== "" ? survey.objectType : "N/A"}</p>
              <p className={styles.cell}>{survey.datePerformed !== "" ? survey.datePerformed : "N/A"}</p>
              <p className={styles.cell}>{survey.performer !== "" ? survey.performer : "N/A"}</p>
              <p className={styles.cell}>{survey.client !== "" ? survey.client : "N/A"}</p>
            </div>
          </div>
        );
      })
    );

  const webList = () => {
    return (
      <>
        <div className={styles.listItemHeader}>
          <p className={styles.cellHeader}>Project</p>
          <p className={styles.cellHeader}>Date</p>
          <p className={styles.cellHeader}>Performed by</p>
          <p className={styles.cellHeader}>Client</p>
        </div>
        {isFetching ? (
          <div className={styles.spinnerWrapper}>
            <LoadingSpinner />
          </div>
        ) : isError ? (
          <NetworkError />
        ) : (
          data
        )}
      </>
    );
  };
  return (
    <div className={isMobile ? styles.listContainerMobile : styles.listContainer}>
      {isMobile ? <MobileSurveyListItems surveys={surveys} isFetching={isFetching} isError={isError} /> : webList()}
    </div>
  );
};

export default SurveyListItems;
