import { AnimatePresence } from "framer-motion";
import React, { ReactInstance, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import URL from "../common/URL";
import PrimaryButton from "../common/PrimaryButton";
import Result from "./Result";

interface ICompletedContent {
  date: string;
  surveyResult: ISurveyResult;
  answers: any;
  buttonText: string;
  allRecommendations: ILocale[];
}

const CompletedContent: React.FC<ICompletedContent> = ({ date, surveyResult, answers, buttonText, allRecommendations }) => {
  const { t } = useTranslation();
  const resultRef = useRef<ReactInstance | null>(null);
  const answersRef = useRef<ReactInstance | null>(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const navigate = useNavigate();

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <>
      <Result _props={{ answers, date, surveyResult, allRecommendations }} ref={resultRef} />
      <div className="mx-4 flex w-fit max-w-[700px] flex-col items-center bg-white">
        <div className="my-4 flex flex-col rounded-2xl p-8 shadow-md">
          <h3 className=" text-[28px] font-semibold uppercase" style={{ fontFamily: "Campton-Light" }}>
            {t("url_sustainableSolutionsKnowledgeCenterText")}
          </h3>
          <p className="mt-4">
            {t("contactInfo", { context: "supportStart" })}
            <URL
              base="url"
              context="sustainableSolutionsKnowledgeCenterLink"
              text="sustainableSolutionsKnowledgeCenterText"
            />
            {t("contactInfo", { context: "supportMiddle" })}
            <URL base="url" context="localContactsLink" text="localContactsText" />
            {t("contactInfo", { context: "supportEnd" })}
          </p>
          <p className="mt-4">
            {t("contactInfo", { context: "scanningStart" })}
            <URL base="url" context="scanningLink" text="scanningText" />
            {t("contactInfo", { context: "scanningEnd" })}
          </p>
          <img
            src="/sustainabilityApproach.png"
            className="mt-4 h-[100px] w-[100px] self-center"
            key="/sustainabilityApproach.png"
            alt="sustainability approach"
          />
        </div>
        <div className="mt-2 flex w-full flex-wrap justify-evenly gap-4 md:gap-0">
          <PrimaryButton text="contactInfo_saveAsPdf" onClick={() => (modalOpen ? close() : open())} />
          <PrimaryButton text={buttonText} onClick={() => navigate("/")} isSecondary />
        </div>
      </div>
      <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
        {modalOpen && (
          <Modal
            handleClose={close}
            answers={surveyResult.rawData}
            client={answers.projectInfo.client}
            resultRef={resultRef}
            answersRef={answersRef}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default CompletedContent;
