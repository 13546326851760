import React from "react";

import { useMsal } from "@azure/msal-react";
import { AFRY_Logotype_Vertical_Explainer } from "../assets";
import { loginRequest } from "../auth/msalConfig";
import PrimaryButton from "../components/common/PrimaryButton";

const LoginPage: React.FC = () => {
  const { instance } = useMsal();
  const loginHandler = (): void => {
    instance.loginRedirect(loginRequest).catch(console.log);
  };

  return (
    <div className="flex h-screen  w-full justify-center">
      <div className="flex h-full w-full max-w-[1440px] flex-col items-center justify-center px-8 md:flex-row">
        <div className="flex w-1/2 flex-col items-center gap-8 md:gap-12">
          <p
            style={{ fontFamily: "Campton-Light" }}
            className="text-center text-3xl font-medium tracking-wide text-black md:text-5xl xl:text-7xl"
          >
            Sustainability <br /> Reflection Tool
          </p>
          <PrimaryButton text={"header_login"} onClick={loginHandler} fontSize="text-2xl md:text-4xl" />
        </div>
        <img
          src={AFRY_Logotype_Vertical_Explainer}
          className="w-3/5 min-w-[200px] max-w-[1000px]"
          alt="Afry, ÅF Pöyry"
        />
      </div>
    </div>
  );
};

export default LoginPage;
