import React from "react";
import { Environment, Social, Socioeconomic, EmptyLegend } from "../../assets";

interface ISymbolLegendProps {
  typeOfSymbol: string;
}

const SymbolLegend: React.FC<ISymbolLegendProps> = ({ typeOfSymbol }) => {
  const symbol =
    typeOfSymbol.includes("Environment")
      ? Environment
      : typeOfSymbol.includes("Social")
      ? Social
      : typeOfSymbol.includes("Socio")
      ? Socioeconomic
      : EmptyLegend;

  const size = 20;
  return <img src={symbol} alt={typeOfSymbol + " symbol"} height={size} width={size} style={{ minWidth: size }} />;
};

export default SymbolLegend;
