import React from "react";
import { motion } from "framer-motion";
import styles from "./CustomButton.module.css";

interface IButtonProps {
  text: string;
  onClick: () => void;
  classNames?: string;
}

const CustomButton: React.FC<IButtonProps> = ({ text, onClick, classNames }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      className={`${styles.newButton} ${classNames}`}
      type="button"
      onClick={onClick}
    >
      {text}
    </motion.button>
  );
};

export default CustomButton;
