import { BASE_LOCALE } from "../context/SurveyContextProvider";

export function findNestedObj(entireObj: any, keyToFind: any, valToFind: any): IValue {
  let foundObj: IValue = { subject: { ...BASE_LOCALE }, targetAreas: [], id: "" };
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

const getProjectInfo = (answers: any) => {
  const info: IProjectInfo = {
    objectType: answers.TypeOfObject,
    client: answers.Client,
    projectDescription: answers.ProjectDescription,
    consultantsNames: answers.NamesOfConsultants,
    roles: answers.RolesInProject,
    strategies: answers.Strategies,
    budget: answers.EstimatedBudget,
  };

  if (answers["RolesInProject-Comment"] !== undefined) {
    const place = info.roles.findIndex((role) => role === "other");
    info.roles[place] = answers["RolesInProject-Comment"];
  }

  return info;
};

const getProjectInfluence = (answers: any) => {
  const influence: IProjectInfluence = {
    willBenefitFromSustainabilityGuidance: answers.SustainabilityGuidance,
    offerAddonServices: answers.OfferAddonServices,
    otherAddonServices: answers.OtherAddonServices,
    shouldDoRiskAnalysis: answers.RiskAnalysis,
  };

  return influence;
};

const CreateAnswerObject = (answers: any, contextValue: any, performer: string, department: string) => {
  const projectInfo = getProjectInfo(answers);
  const projectInfluence = getProjectInfluence(answers);

  const otherImpacts: IOtherImpact[] = [];
  const impacts: IImpact[] = [];
  const impactActions: IImpactAction[] = [];
  let macroSector = 0;

  Object.entries(answers).forEach((item: any) => {
    if (item[0].includes("MacroSector")) {
      const sector = item[0].substring(item[0].length - 1, item[0].length);
      macroSector = +sector;
    } else if (item[0].includes("_managed")) {
      impactActions.push(createImpactAction(item));
    } else if (item[0].includes("_details")) {
      const theItem = item[0].split("_details")[0];
      const impactAction = impactActions.find((action: IImpactAction) => action.title.includes(theItem));
      const impactLocation = impactActions.findIndex((action: IImpactAction) => action.title.includes(theItem));
      if (impactAction) {
        impactAction["comment"] = item[1];
        impactActions[impactLocation] = impactAction;
      }
    } else if (item[0].includes("_impact")) {
      const questionId = item[0].split("_impact")[0];

      if (questionId !== undefined) {
        const storedQuestion: IValue = findNestedObj(contextValue, "id", questionId);

        const impact: IImpact = {
          questionId: questionId,
          // question: storedQuestion.subject,
          impact: item[1],
          targetAreas: storedQuestion.targetAreas,
        };
        impacts.push(impact);
      }
    } else {
      // rework statment to get rid of this else statement
    }
  });

  const answer: IAnswers = {
    afryCoCViolation: answers.afryCoCViolationImpact,
    environmentRisks: answers.environmentRisksImpact,
    svhc: answers.svhcImpact,
    projectInfo: projectInfo,
    projectInfluence: projectInfluence,
    impactAreas: impacts,
    impactActions: impactActions,
    otherImpacts: otherImpacts,
    macroSector: macroSector,
    performer: performer,
    department: department,
    rawData: answers,
  };

  return answer;
};

export default CreateAnswerObject;

const createImpactAction = (item: any) => {
  return {
    title: item[0],
    action: item[1],
    comment: "",
  };
};
