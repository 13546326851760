import { selectedLanguage } from "../../Localization/Localization";
import { BASE_LOCALE } from "../../context/SurveyContextProvider";
import imagesPool from "../../data/imagesPool";

const generateChoices = (selectedImages: number[]) => {
  return selectedImages.map((imageNumber: number, i: number) => {
    return {
      value: i + 1,
      imageLink: imagesPool[imageNumber - 1].src,
    };
  });
};

const generateImageGrid = (name: string, title: ILocale, choices: IImage[]) => {
  return {
    type: "imagepicker",
    name: name,
    title: title,
    enableIf: "{" + name + ".length} < 0",
    hideNumber: true,
    imageWidth: "100px",
    imageHeight: "100px",
    choices: choices,
  };
};

const hr = "<hr style=height:10px;visibility:hidden;border:none />";

export const addHtml = (text: ILocale, htmlTag: string, style = "", descAsterisk = "") => {
  const newText: any = { ...text };
  
  Object.entries(text).forEach((entry) => {
    
    if (entry[1] !== "") {
      if (htmlTag === "a" && text.link) {
        const descPrefix = descAsterisk !== "" ? descAsterisk + ") " : "";
        newText[entry[0]] =
        descPrefix +
        `<${htmlTag} target=_blank rel=noreferrer href=${text.link} ${style ? ` style=${style}` : ""}>` +
        entry[1] +
        `</${htmlTag}>`;
      } else {
        newText[entry[0]] = `<${htmlTag} ${style ? ` style=${style}` : ""}>` + entry[1] + `</${htmlTag}>`;
      }
    }
  });

  return newText;
};

const combineHTML = (exampleOfResult: any) => {
  const header = addHtml(exampleOfResult.contentHeader, "h3", "margin-bottom:8px");
  const text = addHtml(exampleOfResult.contentText, "p", "margin-bottom:8px");
  const em = addHtml(exampleOfResult.contentEM, "em");

  const combined: any = { ...header };

  for (let index = 0; index < Object.entries(header).length; index++) {
    const head: any = Object.entries(header)[index][1];
    const txt: any = Object.entries(text)[index][1];
    const italic: any = Object.entries(em)[index][1];

    const key = Object.entries(combined)
      .filter((ent, i) => i === index)
      .map((val) => val[0])[0];

    if (key === "id") {
      combined[key] = head + "_" + txt + "_" + italic;
    } else {
      combined[key] = head + txt + italic;
    }
  }

  return combined;
};

const buildRecommendations = (obj: any) => {
  let htmlString = "";
  const header: any = Object.entries(obj).filter((entry) => entry[0].includes("Header"))[0];
  const items: any[] = Object.entries(obj).filter((entry) => !entry[0].includes("Header"));

  htmlString += "<h5 style=margin-top:0 >" + header[1][selectedLanguage] + "</h5>" + hr;
  items.forEach((item: any, index: number) => {
    if (index === 0) {
      htmlString += "<ul>";
    }
    htmlString += "<li>" + item[1][selectedLanguage] + "</li>";
    if (index + 1 === items.length) {
      htmlString += "</ul>";
    }
  });

  return htmlString;
};

const ExampleOfResult = (exampleOfResult: any) => {
  return {
    elements: [
      {
        type: "html",
        html: combineHTML(exampleOfResult),
      },
      {
        type: "html",
        html: buildRecommendations(exampleOfResult.recommendation),
        name: "recommendationDemo",
      },
      {
        type: "html",
        html: addHtml(exampleOfResult.goalsHeader, "h4", "margin:0"),
        name: "goalsHeader",
      },
      generateImageGrid("imageselector1", exampleOfResult.risks, generateChoices([1, 3, 5, 8, 13])),
      generateImageGrid("imageselector2", exampleOfResult.potential, generateChoices([5, 7, 8, 9, 13, 15])),
      {
        type: "html",
        html: buildRecommendations(exampleOfResult.gaps),
        name: "gapsDemo",
      },
    ],
  };
};

export default ExampleOfResult;
