import React, { useEffect, useState } from "react";

import { getSurveys } from "../api/ApiService";
import SurveyListItems from "../components/SurveyListItems/SurveyListItems";

const SurveyList: React.FC = () => {
  const [surveys, setSurveys] = useState<ISurveyListItem[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchSurveys = async (): Promise<any> => {
      return await getSurveys();
    };
    const response = fetchSurveys();

    response.then((result: any) => {
      if (result.data) {
        setSurveys(result.data);
      } else {
        setIsError(true);
      }
      setIsFetching(false);
    });
  }, []);

  return (
    <div className="flex flex-col items-center bg-white">
      <SurveyListItems surveys={surveys} isFetching={isFetching} isError={isError} />
    </div>
  );
};

export default SurveyList;
