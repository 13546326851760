import Axios, { AxiosResponse } from "axios";
import { graphConfig } from "../auth/msalConfig";
import acquireAccessToken from "./token-aquisition";

const baseURL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_LOCAL_API;

const axios = Axios.create();

axios.interceptors.request.use(
  async (config) => {
    let shouldFetchImage = false;
    if (config.url?.includes(graphConfig.graphMeEndpoint)) {
      shouldFetchImage = true;
    }
    const token = await acquireAccessToken(shouldFetchImage);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const getQuestionnaire = async (): Promise<AxiosResponse> => {
  return axios.get(baseURL + "/questionnaire").catch((error) => error);
};

export const getRecommendations = async (): Promise<AxiosResponse> => {
  return axios.get(baseURL + "/recommendations").catch((error) => error);
};

export const getAppLocales = async (): Promise<AxiosResponse> => {
  return axios.get(baseURL + "/appLocales").catch((error) => error);
};

export const getSurveys = async (): Promise<AxiosResponse> => {
  return axios.get(baseURL + "/surveys").catch((error) => error);
};

export const getSurvey = async (id: string): Promise<AxiosResponse> => {
  return axios.get(baseURL + `/surveys/${id}`).catch((error) => Promise.reject(error));
};

export const getSurveyResult = async (answers: IAnswers): Promise<AxiosResponse> => {
  return axios.post(baseURL + "/surveys", JSON.stringify(answers)).catch((error) => error);
};

export const getProfileImage = async () => {
  return axios
    .get(graphConfig.graphMeEndpoint + "/photo/$value", {
      responseType: "blob",
    })
    .catch((error) => Promise.reject(error));
};

export const getGroups = async () => {
  return axios
    .post(graphConfig.graphMeEndpoint + "/getMemberGroups", {
      securityEnabledOnly: true,
    })
    .catch((error) => Promise.reject(error));
};

export const getMe = async () => {
  return axios.get(graphConfig.graphMeEndpoint + "?$select=department").catch((error) => Promise.reject(error));
};
