import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./english.json";
import sv from "./swedish.json";
import da from "./danish.json";
import de from "./german.json";
import fi from "./finnish.json";
import no from "./norwegian.json";

export const LANGUAGES = {
  en,
  sv,
  da,
  de,
  fi,
  no,
};

export const shortLocaleNames: { key: string; value: string }[] = [
  {
    key: "danish",
    value: "da",
  },
  {
    key: "english",
    value: "en",
  },
  {
    key: "finnish",
    value: "fi",
  },
  {
    key: "german",
    value: "de",
  },
  {
    key: "norwegian",
    value: "no",
  },
  {
    key: "swedish",
    value: "sv",
  },
];

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV !== "production",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: { en },
    });
}

if (!shortLocaleNames.map((locale) => locale.value).includes(i18n.language)) {
  i18n.changeLanguage("en");
}
export let selectedLanguage: keyof ILanguages =
  i18n.language === "en" ? "default" : (i18n.language as keyof ILanguages);

export const updateSelectedLanguage = (lang: keyof ILanguages) => {
  selectedLanguage = lang === "en" ? "default" : lang;
};

export default i18n;
