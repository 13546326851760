import { BASE_LOCALE } from "../../context/SurveyContextProvider";
import { selectedLanguage } from "../../Localization/Localization";
import AboutProject from "./AboutProject";
import AboutSRT from "./AboutSRT";
import ExampleOfEvaluation from "./ExampleOfEvaluation";
import ExampleOfResult, { addHtml } from "./ExampleOfResult";
import Influence from "./Influence";
import SustainabilityAtAfry from "./SustainabilityAtAfry";
import SustainableDevelopmentGoals from "./SustainableDevelopmentGoals";
import Taxonomy from "./Taxonomy";
import { html, setQuestions, setConditionalCheckboxes, checkIfEmptyImpacts, setConditionalLastOther } from "./utils";

const findDescriptionIDs = (bigObj: any) => {
  const descriptionIDs: any[] = [];

  const searchForDescriptionIDs = (obj: any) => {
    if (typeof obj === "object" && obj !== null) {
      if (Object.prototype.hasOwnProperty.call(obj, "descriptionId")) {
        descriptionIDs.push(obj.descriptionId);
      }
      for (const key in obj) {
        searchForDescriptionIDs(obj[key]);
      }
    }
  };

  searchForDescriptionIDs(bigObj);
  return descriptionIDs;
};

export const combineHTML = (elementsToCombine: ILocale[], shouldBreak = false, shouldWrap = false) => {
  const strippedElements = elementsToCombine.map((element: ILocale) => {
    const {link, endText, id, ...restElement} = element;
    return restElement;
  });
  const combined: any = { ...strippedElements[0] };
  for (let index = 0; index < Object.entries(strippedElements[0]).length; index++) {
    const values = strippedElements.map((element) => {
      const value: any = Object.entries(element)[index][1];
      return value;
    });

    const key = Object.entries(combined)
      .filter((ent, i) => i === index)
      .map((val) => val[0])[0];

    const locales: string[] = Object.keys({ ...BASE_LOCALE }).map((key) => key);

    if (locales.includes(key) && values.every((val) => val !== "")) {
      const baseValue = values.join(shouldBreak ? "<br>" : "");
      const newValue = shouldWrap ? " (" + baseValue + ")" : baseValue;
      combined[key] = newValue;
    }
  }

  return combined;
};

export const setSection = (section: ISector, questionChoices: IQuestionChoices) => {
  return section.impactAreas.map((area: IArea, i: number) => {
    const isLast = section.impactAreas.length === i + 1;
    const elements = [
      html(section.headerText, area.name),
      ...setQuestions(area, questionChoices, isLast ? section.other : null),
    ];
    const descriptions: { type: string; name: string; html: ILocale } = {
      type: "html",
      name: area.name + "_Description",
      html: { ...BASE_LOCALE },
    };

    if (area.description) {
      const newDesc: ILocale[] = [];

      const descriptionIDs = findDescriptionIDs(area);

      if (descriptionIDs.length > 0) {
        const areaDescs = area.description.filter((desc: any) => descriptionIDs.includes(desc.id));
        areaDescs.forEach((desc: ILocale, index: number) => {
          if (descriptions.html[selectedLanguage] !== "") {
            descriptions.html[selectedLanguage] += "<br>";
          }
          if (desc.endText) {
            const link = addHtml(desc, "a", "", "*".repeat(index + 1));
            let endText: any = desc.endText;
            if (endText.preFix) {
              const link2 = addHtml(endText, "a");
              endText = combineHTML([endText.preFix, link2], false, true);
            }
            const combined = combineHTML([link, endText]);
            newDesc.push(combined);
          } else {
            descriptions.html[selectedLanguage] += "*".repeat(index + 1) + ") " + desc[selectedLanguage];
          }
        });

        if (newDesc.length > 0) {
          descriptions.html = combineHTML(newDesc, true);
        }
      }
    }

    if (descriptions.html[selectedLanguage] !== "") {
      elements.push(descriptions);
    }

    return {
      elements: elements,
    };
  });
};

export const setAffectedAreasSection = (
  sector: ISector,
  headerText: IHeader,
  questionChoices: IQuestionChoices,
  sectionReflection: ISectionReflection
) => {
  const impactAreas: IArea[] = [];

  sector.impactAreas.forEach((area: IArea) => {
    if (area.name.includes("Impact")) {
      impactAreas.push(area);
    }
  });

  const actionableAreas = impactAreas.map((area: IArea) => {
    return setConditionalCheckboxes(area, questionChoices, sectionReflection);
  });

  if (sector.other) {
    actionableAreas.push(setConditionalLastOther(sector.other, sectionReflection, questionChoices));
  }

  const emptyComponent = checkIfEmptyImpacts(impactAreas, sectionReflection, sector.other);

  return {
    elements: [
      {
        type: "html",
        html: headerText.text,
        name:
          sector.headerText.name !== undefined
            ? sector.headerText.name + "_Reflection_headerText"
            : "reflection_headerText",
      },
      ...actionableAreas,
      emptyComponent,
    ],
  };
};

export const reflectionHeader = (sectionReflection: ISectionReflection) => {
  const header = addHtml(sectionReflection.impactsText.header, "h3", "margin-bottom:8px");
  const middle = addHtml(sectionReflection.impactsText.middle, "p", "margin-bottom:8px");
  const end = addHtml(sectionReflection.impactsText.end, "p", "margin-bottom:8px");

  const newHTML = combineHTML([header, middle, end]);
  return {
    text: newHTML,
  };
};

const excludeObjectsWithIds = (obj: any, idsToExclude: any[]) => {
  // Check if the current object is an object
  if (typeof obj === "object" && obj !== null) {
    // Check if the current object has an "id" field and the "id" is in the exclusion list
    if (Object.prototype.hasOwnProperty.call(obj, "id") && idsToExclude.includes(obj.id)) {
      return null; // Exclude this object by returning null
    }

    // Recursively process all properties of the current object
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Recursively call the function for nested objects or arrays
        obj[key] = excludeObjectsWithIds(obj[key], idsToExclude);
      }
    }

    // Filter out null values from arrays (removing the excluded objects)
    if (Array.isArray(obj)) {
      obj = obj.filter((item) => item !== null);
    }
  }

  return obj; // Return the modified object
};

const removeEmptyImpactAreas = (sector: ISector) => {
  const section = { ...sector };

  const newAreas = section.impactAreas.filter((area) => (area.impacts && area.impacts.length > 0) || area.impact);
  section.impactAreas = newAreas;
  return section;
};

const Questions = (context: IContextValues, filters: string[]) => {
  const environment = setSection(
    removeEmptyImpactAreas(excludeObjectsWithIds(context.value.environment, filters)),
    context.value.utils.questionChoices
  );
  const environmentReflection = setAffectedAreasSection(
    context.value.environment,
    reflectionHeader(context.value.utils.sectionReflection),
    context.value.utils.questionChoices,
    context.value.utils.sectionReflection
  );
  const social = setSection(
    removeEmptyImpactAreas(excludeObjectsWithIds(context.value.social, filters)),
    context.value.utils.questionChoices
  );
  const socialReflection = setAffectedAreasSection(
    context.value.social,
    reflectionHeader(context.value.utils.sectionReflection),
    context.value.utils.questionChoices,
    context.value.utils.sectionReflection
  );
  const socioEconomic = setSection(
    removeEmptyImpactAreas(excludeObjectsWithIds(context.value.socioEconomic, filters)),
    context.value.utils.questionChoices
  );
  const socioEconomicReflection = setAffectedAreasSection(
    context.value.socioEconomic,
    reflectionHeader(context.value.utils.sectionReflection),
    context.value.utils.questionChoices,
    context.value.utils.sectionReflection
  );

  return {
    locale: selectedLanguage ? selectedLanguage : "en",
    showPreviewBeforeComplete: "showAnsweredQuestions",
    showCompletedPage: false,
    showNavigationButtons: "none",
    focusFirstQuestionAutomatic: false,
    questionDescriptionLocation: "underInput",
    pages: [
      SustainabilityAtAfry(context.value.introduction.sustainabilityAtAfry),
      SustainableDevelopmentGoals(context.value.introduction.sustainableDevelopmentGoals),
      ExampleOfResult(context.value.introduction.exampleOfResult),
      AboutSRT(context.value.introduction.aboutSRT),
      ExampleOfEvaluation(
        context.value.introduction.exampleOfEvaluation,
        context.value.utils,
        context.value.environment.impactAreas.filter((area) => area.name === "resourceManagementImpact")[0],
        context.value.social.impactAreas
          .filter((area) => area.name === "GenderEqualityImpact")[0]
          .impacts!.filter((impact) => impact.id === "AspectAccessChildrenElders")[0]
      ),
      ...AboutProject(context.value.introduction.aboutProject),
      Taxonomy(context.value.taxonomy),
      ...environment,
      environmentReflection,
      ...social,
      socialReflection,
      ...socioEconomic,
      socioEconomicReflection,
      Influence(context.value.influence),
    ],
    triggers: [
      {
        type: "skip",
        expression: "{skipIntroduction} = 'Skip'",
        gotoName: "TypeOfObject", // after intro
        //gotoName: "SustainabilityGuidance", // last page
      },
    ],
  };
};

export default Questions;
