import { selectedLanguage } from "../../Localization/Localization";
import { addHtml } from "./ExampleOfResult";
import { combineHTML } from "./Questions";

interface IChoiceObject {
  value: string | boolean;
  text: string | ILocale;
}

interface ICommentField {
  name: string;
  title: string | ILocale;
  hideNumber: boolean;
  type: string;
  visibleIf?: string;
}

interface IBox {
  type: string;
  name: string;
  title: string | ILocale;
  colCount: number;
  hideNumber: boolean;
  isRequired: boolean;
  choices: IChoiceObject[];
  visibleIf?: string;
  hasNone?: boolean;
  noneText?: string;
}

const rowObjects = (rows: IValue[]) => {
  return rows.map((rowText: IValue) => {
    return {
      targetAreas: rowText.targetAreas,
      title: rowText.subject,
      id: rowText.id,
    };
  });
};

const combineSubQ = (tempSubQ: any) => {
  const { id: startID, ...start } = tempSubQ.titleStart;
  const contentLink1 = addHtml(tempSubQ.titleLink1, "a");
  const { id: linkID1, link: linkText1, ...translations1 } = contentLink1;
  const { id: middleId, ...restMiddle } = tempSubQ.titleMiddle;
  const contentLink2 = addHtml(tempSubQ.titleLink2, "a");
  const { id: linkID2, link: linkText2, ...translations2 } = contentLink2;

  const combined: any = { ...start };

  for (let index = 0; index < Object.entries(start).length; index++) {
    const first: any = Object.entries(start)[index][1];
    const second: any = Object.entries(translations1)[index][1];
    const third: any = Object.entries(restMiddle)[index][1];
    const fourth: any = Object.entries(translations2)[index][1];

    const key = Object.entries(combined)
      .filter((ent, i) => i === index)
      .map((val) => val[0])[0];

    combined[key] = first + second + third + fourth;
  }
  combined.id = startID + "_" + tempSubQ.titleLink1.id + "_" + middleId + "_" + tempSubQ.titleLink2.id;

  return combined;
};

const checkboxes = (area: IArea, questionChoices: IQuestionChoices) => {
  const boxes: IBox[] = [];

  if (area.impacts) {
    rowObjects(area.impacts).forEach((row) => {
      boxes.push({
        type: "checkbox",
        name: row.id,
        title: row.title,
        colCount: 2,
        hideNumber: true,
        isRequired: false,
        choices: [{ value: "relevant", text: " " }],
      });
    });
  }

  if (area.subQuestion) {
    let title = { ...area.subQuestion.title };
    if (area.subQuestion.name === "svhcImpact") {
      const tempSubQ: any = { ...area.subQuestion };
      title = combineSubQ(tempSubQ);
    }

    boxes.push(radiogroup(area.subQuestion.impact.id, title, questionChoices));
  }

  return {
    type: "panel",
    name: area.name,
    title: area.title,
    elements: [...boxes],
  };
};

const conditionalCheckboxes = (
  area: IArea,
  questionChoices: IQuestionChoices,
  sectionReflection: ISectionReflection
) => {
  let subjectArea = "";
  const elements: any = [];

  const radioChoices: IChoiceObject[] = [
    {
      value: true,
      text: questionChoices.yes,
    },
    {
      value: false,
      text: questionChoices.no,
    },
    {
      value: questionChoices.dontKnow.default,
      text: questionChoices.dontKnow,
    },
  ];

  if (area.impact) {
    subjectArea = area.header;

    let title = { ...area.title };
    if (area.subTitle) {
      const link = addHtml(area.subTitle, "a");
      title = addHtml(combineHTML([title, link]), "h4");
    } else {
      title = addHtml({ ...title }, "h4");
    }

    const headerHtml = {
      type: "html",
      html: title,
      visibleIf: "{" + area.impact.id + "}='true'",
      title: "questionReflectId",
    };
    const radioGroup: IBox = {
      choices: radioChoices,
      colCount: 3,
      hideNumber: true,
      isRequired: true,
      name: area.category + "_" + area.impact.id + "_managed",
      title: sectionReflection.sufficientlyManagedTitle,
      visibleIf: "{" + area.impact.id + "}='true'",
      type: "radiogroup",
    };
    const comment: ICommentField = {
      hideNumber: true,
      name: area.impact.id + "_details",
      title: sectionReflection.actionCommentTitle,
      visibleIf: "{" + area.impact.id + "}='true'",
      type: "comment",
    };
    elements.push(headerHtml);
    elements.push(radioGroup);
    elements.push(comment);
  }

  const checkChoices: IChoiceObject[] = [];
  checkChoices.push({ value: "positive", text: questionChoices.positive });
  checkChoices.push({ value: "negative", text: questionChoices.negative });

  if (area.impacts) {
    subjectArea = area.title[selectedLanguage].split(",")[0];
    rowObjects(area.impacts).forEach((row) => {
      const title = row.title[selectedLanguage].replace(/\*/g, "");
      const headerHtml = {
        type: "html",
        html: "<h4>" + title + "</h4>",
        visibleIf: "{" + row.id + ".length}>0",
        title: "questionReflectId",
      };
      const checkBox: IBox = {
        choices: checkChoices,
        colCount: 3,
        hideNumber: true,
        isRequired: true,
        name: row.id + "_impact",
        title: sectionReflection.estimateRiskTitle,
        visibleIf: "{" + row.id + ".length}>0",
        type: "checkbox",
        noneText: questionChoices.dontKnow[selectedLanguage],
        hasNone: true,
      };
      const radioGroup: IBox = {
        choices: radioChoices,
        colCount: 3,
        hideNumber: true,
        isRequired: true,
        name: area.category + "_" + row.id + "_managed",
        title: sectionReflection.sufficientlyManagedTitle,
        visibleIf: "{" + row.id + ".length}>0",
        type: "radiogroup",
      };
      const comment: ICommentField = {
        hideNumber: true,
        name: row.id + "_details",
        title: sectionReflection.actionCommentTitle,
        visibleIf: "{" + row.id + ".length}>0",
        type: "comment",
      };
      elements.push(headerHtml);
      elements.push(checkBox);
      elements.push(radioGroup);
      elements.push(comment);
    });
  }

  if (area.subQuestion) {
    const headerHtml = {
      type: "html",
      html: "<h4>" + area.subQuestion.header[selectedLanguage] + "</h4>",
      visibleIf: "{" + area.subQuestion.impact.id + "}='true'",
      title: "questionReflectId",
    };
    const radioGroup: IBox = {
      choices: radioChoices,
      colCount: 3,
      hideNumber: true,
      isRequired: true,
      name: area.category + "_" + area.subQuestion.impact.id + "_managed",
      title: sectionReflection.sufficientlyManagedTitle,
      visibleIf: "{" + area.subQuestion.impact.id + "}='true'",
      type: "radiogroup",
    };
    const comment: ICommentField = {
      hideNumber: true,
      name: area.subQuestion.impact.id + "_details",
      title: sectionReflection.actionCommentTitle,
      visibleIf: "{" + area.subQuestion.impact.id + "}='true'",
      type: "comment",
    };
    elements.push(headerHtml);
    elements.push(radioGroup);
    elements.push(comment);
  }

  const actionName = area.name + "_action";
  
  return {
    type: "panel",
    name: actionName,
    title: subjectArea,
    elements: elements,
  };
};

export const setConditionalLastOther = (
  other: IOther,
  sectionReflection: ISectionReflection,
  questionChoices: IQuestionChoices
) => {
  const checkChoices: IChoiceObject[] = [];
  checkChoices.push({ value: "positive", text: questionChoices.positive });
  checkChoices.push({ value: "negative", text: questionChoices.negative });

  const radioChoices: IChoiceObject[] = [
    {
      value: true,
      text: questionChoices.yes,
    },
    {
      value: false,
      text: questionChoices.no,
    },
    {
      value: questionChoices.dontKnow.default,
      text: questionChoices.dontKnow,
    },
  ];

  const otherElements = [];
  let otherName = "";
  if (other) {
    otherName = other.name + "_action_panel";
    const otherCheckBox: IBox = {
      choices: checkChoices,
      colCount: 3,
      hideNumber: true,
      isRequired: true,
      name: other.id + "_impact",
      title: sectionReflection.estimateRiskTitle,
      visibleIf: "{" + other.id + ".length}>0",
      type: "checkbox",
      noneText: questionChoices.dontKnow[selectedLanguage],
      hasNone: true,
    };
    const otherRadioGroup: IBox = {
      type: "radiogroup",
      name: other.name.split("_")[0] + "_" + other.id + "_managed",
      title: sectionReflection.sufficientlyManagedTitle,
      visibleIf: "{" + other.id + ".length}>0",
      colCount: 3,
      hideNumber: true,
      isRequired: true,
      choices: radioChoices,
    };
    const otherComment: ICommentField = {
      hideNumber: true,
      name: other.id + "_details",
      title: sectionReflection.actionCommentTitle,
      type: "comment",
      visibleIf: "{" + other.id + ".length}>0",
    };
    otherElements.push(otherCheckBox);
    otherElements.push(otherRadioGroup);
    otherElements.push(otherComment);
  }

  return {
    type: "panel",
    name: otherName,
    title: other.title[selectedLanguage],
    elements: otherElements,
  };
};

export const checkIfEmptyImpacts = (areas: IArea[], sectionReflection: ISectionReflection, other: IOther) => {
  let isvisString = "";
  areas.forEach((area: IArea, i: number) => {
    area.impacts?.forEach((row) => {
      isvisString = isvisString + "{" + row.id + ".length} == 0 and";
    });

    const impactOrSubIsVis = (name: string) => {
      if (i + 1 === areas.length) {
        isvisString += "({" + name + "} empty or {" + name + "}='false')";
      } else {
        isvisString += "({" + name + "} empty or {" + name + "}='false') and";
      }
    };

    if (area.impact) {
      impactOrSubIsVis(area.impact.id);
    }
    if (area.subQuestion) {
      impactOrSubIsVis(area.subQuestion.impact.id);
    }
  });

  if (other) {
    isvisString += "{" + other.id + "} empty";
  }

  const textWithLink = combineHTML([
    sectionReflection.impactsEmpty.text,
    addHtml(sectionReflection.impactsEmpty.link, "a"),
  ]);

  return {
    type: "html",
    html: combineHTML([addHtml(sectionReflection.impactsEmpty.header, "h1"), addHtml(textWithLink, "p")]),
    visibleIf: isvisString,
  };
};

const radiogroup = (name: string, title: ILocale, questionChoices: IQuestionChoices) => {
  return {
    name: name,
    title: title,
    type: "radiogroup",
    colCount: 1,
    hideNumber: true,
    isRequired: false,
    choices: [
      { value: true, text: questionChoices.yes },
      { value: false, text: questionChoices.no },
    ],
  };
};

const text = (other: IOther) => {
  return {
    name: other.id,
    title: other.title,
    type: "text",
    hideNumber: true,
  };
};

export const html = (headerText: IHeader, name: string, htmlTag?: string) => {
  let asHTML = "<h3 style=margin-bottom:8px >" + headerText.text[selectedLanguage] + "</h3>";
  if (headerText.subText) {
    asHTML += headerText.subText[selectedLanguage];
  }

  return {
    type: "html",
    html: asHTML,
    name: headerText.name !== undefined ? headerText.name : name + "headerText",
  };
};

export const htmlNote = (headerText: string) => {
  return {
    type: "html",
    html: headerText,
  };
};

export const setConditionalCheckboxes = (
  area: IArea,
  questionChoices: IQuestionChoices,
  sectionReflection: ISectionReflection
) => {
  return conditionalCheckboxes(area, questionChoices, sectionReflection);
};

export const setQuestions = (area: IArea, questionChoices: IQuestionChoices, other: any) => {
  const elements: any = []; // extremely weird type of the form because a bunch of different types of questions

  if (area.impacts) {
    elements.push(checkboxes(area, questionChoices));
  }
  if (area.impact) {
    let title = { ...area.title };
    if (area.subTitle) {
      const link = addHtml(area.subTitle, "a");
      title = combineHTML([title, link]);
    }
    elements.push(radiogroup(area.impact.id, title, questionChoices));
  }
  if (other) {
    elements.push(text(other));
  }

  return elements;
};
