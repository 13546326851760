import { selectedLanguage } from "../../Localization/Localization";

const ExampleOfEvaluation = (
  exampleOfEvaluation: any,
  utils: ISectionUtils,
  resourceManagement: IArea,
  accessYoungElderlyText: IValue
) => {
  const hr = "<hr style=height:10px;visibility:hidden;border:none />";

  const htmlHeader = () => {
    const htmlString =
      "<h3>" +
      exampleOfEvaluation.header[selectedLanguage] +
      "</h3>" +
      hr +
      "<p>" +
      exampleOfEvaluation.headerDesc[selectedLanguage] +
      "</p>";
    return htmlString;
  };

  return {
    elements: [
      {
        type: "html",
        html: htmlHeader(),
      },
      {
        type: "html",
        name: "exampleText",
        html: exampleOfEvaluation.example,
      },
      {
        type: "panel",
        title: resourceManagement.title,
        elements:
          resourceManagement.impacts &&
          resourceManagement.impacts.slice(0, 3).map((impact, i) => {
            return {
              name: "example" + (i + 1),
              type: "checkbox",
              title: impact.subject,
              colCount: 2,
              hideNumber: true,
              choices: [{ value: "reflect", text: " " }],
              enableIf: "{example1}='test'",
            };
          }),
      },
      {
        type: "panel",
        title: " ",
        hideTitle: true,
        name: " ",
        elements: [
          {
            type: "html",
            html: exampleOfEvaluation.checked,
          },
          {
            type: "html",
            html: exampleOfEvaluation.empty,
          },
          {
            type: "html",
            html: exampleOfEvaluation.both,
          },
          {
            type: "html",
            html: exampleOfEvaluation.longTerm,
          },
          {
            type: "html",
            html: exampleOfEvaluation.reflect,
          },
        ],
      },
      {
        type: "panel",
        title: accessYoungElderlyText.subject,
        elements: [
          {
            name: "exampleReflectCheckbox",
            type: "checkbox",
            title: utils.sectionReflection.estimateRiskTitle,
            colCount: 3,
            hideNumber: true,
            noneText: utils.questionChoices.dontKnow,
            hasNone: true,
            choices: [
              { value: "positive", text: utils.questionChoices.positive },
              { value: "negative", text: utils.questionChoices.negative },
            ],
            enableIf: "{example1}='test'",
          },
          {
            name: "exampleReflectRadiogroup",
            type: "radiogroup",
            title: utils.sectionReflection.sufficientlyManagedTitle,
            colCount: 3,
            hideNumber: true,
            noneText: utils.questionChoices.dontKnow,
            hasNone: true,
            choices: [
              { value: true, text: utils.questionChoices.yes },
              { value: false, text: utils.questionChoices.no },
            ],
            enableIf: "{example1}='test'",
          },
          {
            name: "exampleReflectComment",
            type: "comment",
            title: utils.sectionReflection.actionCommentTitle,
            hideNumber: true,
            enableIf: "{example1}='test'",
          },
        ],
      },
      {
        type: "panel",
        title: " ",
        name: " ",
        elements: [
          {
            type: "html",
            html: exampleOfEvaluation.positiveDesc,
          },
          {
            type: "html",
            html: exampleOfEvaluation.negativeDesc,
          },
          {
            type: "html",
            html: exampleOfEvaluation.bothDesc,
          },
          {
            type: "html",
            html: exampleOfEvaluation.reflectDesc,
          },
          {
            type: "html",
            html: exampleOfEvaluation.influenceDesc,
          },
          {
            type: "html",
            html: exampleOfEvaluation.dictionaryDesc,
          },
        ],
      },
    ],
  };
};
export default ExampleOfEvaluation;
