const ADMIN_GROUP = "4b93f3d3-7060-4963-9649-e7d428c2c406";
const YOUR_SURVEY_ID = "yourSurveyUniqueNameOrId";
const HAS_STARTED_A_SURVEY = "hasStartedASurvey";
const SELECTED_SURVEY_FILTER = "selectedSurveyFilter";
const QUESTIONNAIRE = "questionnaire";
const QUESTIONNAIRE_VERSION = 2.6;

export {
  ADMIN_GROUP,
  YOUR_SURVEY_ID,
  HAS_STARTED_A_SURVEY,
  SELECTED_SURVEY_FILTER,
  QUESTIONNAIRE,
  QUESTIONNAIRE_VERSION,
};
