import React from "react";
import * as ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";

const container = document.getElementById("root") as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(<App />);
