import React from "react";
import { cleanTitle } from "../GapList/GapList";
import SymbolLegend from "../common/SymbolLegend";
import styles from "./GapListResult.module.css";
import { selectedLanguage } from "../../Localization/Localization";

interface IGap {
  title: ILocale;
  category: string;
}

interface IGapListProps {
  gapList: IGap[];
}

const GapListResult: React.FC<IGapListProps> = ({ gapList }) => {
  return (
    <div
      id="gapList"
      style={{
        width: "100%",
        display: "flex",
      }}
      className={styles.gapList}
    >
      {gapList
        .sort((a: IGap, b: IGap) => a.category.localeCompare(b.category))
        .map((gap: IGap, i: number) => (
          <div key={gap.title[selectedLanguage] + i} className={styles.legend}>
            <SymbolLegend typeOfSymbol={gap.category} />
            <p>{cleanTitle(gap.title[selectedLanguage])}</p>
          </div>
        ))}
    </div>
  );
};

export default GapListResult;
