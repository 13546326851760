import { selectedLanguage } from "../../Localization/Localization";
import imagesPool from "../../data/imagesPool";

const generateChoices = (start: number, end: number) => {
  return imagesPool.slice(start, end).map((image, i) => {
    return {
      value: i + 1,
      imageLink: image.src,
    };
  });
};

const generateImageGrid = (name: string, choices: IImage[]) => {
  return {
    type: "imagepicker",
    name: name,
    titleLocation: "hidden",
    enableIf: "{" + name + ".length} < 0",
    hideNumber: true,
    imageWidth: "100px",
    imageHeight: "100px",
    choices: choices,
  };
};

const SustainableDevelopmentGoals = (sustainableDevelopmentGoals: any) => {
  const buildHtml = (obj: any) => {
    let htmlString = "";

    htmlString +=
      `<${obj.sustainableDevelopmentGoalsHeader.type}>` +
      obj.sustainableDevelopmentGoalsHeader[selectedLanguage] +
      `</${obj.sustainableDevelopmentGoalsHeader.type}><br>`;
    htmlString +=
      `<${obj.sustainableDevelopmentGoalsText.type}>` +
      obj.sustainableDevelopmentGoalsText[selectedLanguage] +
      `<${obj.sustainableDevelopmentGoalsLink.type} href=${obj.sustainableDevelopmentGoalsLink.link} target=_blank rel=noreferrer >` +
      obj.sustainableDevelopmentGoalsLink[selectedLanguage] +
      `</${obj.sustainableDevelopmentGoalsLink.type}>` +
      `</${obj.sustainableDevelopmentGoalsText.type}>`;

    return htmlString;
  };

  return {
    elements: [
      {
        type: "html",
        html: buildHtml(sustainableDevelopmentGoals),
      },
      generateImageGrid("allGoals", generateChoices(0, 18)),
    ],
  };
};

export default SustainableDevelopmentGoals;
