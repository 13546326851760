import React from "react";
import { useTranslation } from "react-i18next";

interface IPrimaryButtonProps {
  text: string;
  onClick: any;
  fontSize?: string;
  isSecondary?: boolean;
  className?: string;
}

const PrimaryButton: React.FC<IPrimaryButtonProps> = ({
  text,
  onClick,
  fontSize = "text-xl",
  isSecondary = false,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <button
      className={`rounded-md px-8 py-2.5 ${fontSize} tracking-wide shadow-md 
      ${isSecondary ? "bg-black text-white sm:hover:bg-grey2" : "bg-primary text-black "}
      duration-300 ease-in-out motion-reduce:transition-all ${className}`}
      onClick={onClick}
    >
      {t(text)}
    </button>
  );
};

export default PrimaryButton;
