import { selectedLanguage } from "../../Localization/Localization";
import { addHtml } from "./ExampleOfResult";

const hr = "<hr style=height:10px;visibility:hidden;border:none />";

const AboutSRT = (aboutSRT: any) => {
  const htmlDescription = () => {
    const htmlString =
      "<p style=margin-block-end:0>" +
      aboutSRT.description[selectedLanguage] +
      hr +
      aboutSRT.descriptionSections[selectedLanguage] +
      "</p>";
    return htmlString;
  };

  const htmlSection = (header: string, text1: string, text2 = aboutSRT.reflect[selectedLanguage]) => {
    const htmlString = "<h4 >" + header + "</h4>"+ hr + "<p>" + text1 + "</p>" + "<p style=margin:0>" + text2 + "</p>" + hr;
    return htmlString;
  };

  const htmlInfluence = () => {
    const fields = Object.entries(aboutSRT).filter((entry) => entry[0].startsWith("influence"));
    const header: any = fields.filter((field) => field[0].includes("Header"))[0];
    const texts: any[] = fields.filter((field) => !field[0].includes("Header"));

    let htmlString = "<h4 >" + header[1][selectedLanguage] + "</h4>";

    texts.forEach((text) => {
      htmlString += "<p>" + text[1][selectedLanguage] + "</p>";
    });

    return htmlString;
  };

  return {
    elements: [
      {
        type: "html",
        name: "aboutHeader",
        html: addHtml(aboutSRT.header, "h3"),
      },
      {
        type: "image",
        imageLink: "./orientation_1.4.png",
        imageWidth: "800px",
        imageHeight: "250px",
        name: "orientationImage",
      },
      {
        type: "html",
        html: htmlDescription(),
      },
      {
        type: "html",
        html: htmlSection(
          aboutSRT.introductionHeader[selectedLanguage],
          aboutSRT.introductionRow1[selectedLanguage],
          aboutSRT.introductionRow2[selectedLanguage]
        ),
      },
      {
        type: "html",
        html: htmlSection(aboutSRT.environmentalHeader[selectedLanguage], aboutSRT.environmentalDesc[selectedLanguage]),
      },
      {
        type: "html",
        html: htmlSection(aboutSRT.socialHeader[selectedLanguage], aboutSRT.socialDesc[selectedLanguage]),
      },
      {
        type: "html",
        html: htmlSection(aboutSRT.socioHeader[selectedLanguage], aboutSRT.socioDesc[selectedLanguage]),
      },
      {
        type: "html",
        html: htmlInfluence(),
      },
    ],
  };
};

export default AboutSRT;
