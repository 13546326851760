import React from "react";
import { useTranslation } from "react-i18next";

interface IURLProps {
  base: string;
  context: string;
  text: string;
}

const URL: React.FC<IURLProps> = ({ base, context, text }) => {
  const { t } = useTranslation();
  return (
    <a 
    href={t(base, { context: context })} target="_blank" rel="noreferrer">
      {t(base, { context: text })}
    </a>
  );
};

export default URL;
