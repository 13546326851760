import { InteractionRequiredAuthError } from "@azure/msal-common";
import { authRequest, loginRequest, msalInstance } from "../auth/msalConfig";

export default (shouldFetchImage: boolean): Promise<string | void> => {
  const accounts = msalInstance.getAllAccounts();
  const typeOfRequest = shouldFetchImage ? loginRequest : authRequest;
  const request = {
    ...typeOfRequest,
    account: accounts[0],
  };

  return msalInstance
    .acquireTokenSilent(request)
    .then((response: any) => response.accessToken)
    .catch((err: any) => {
      if (err instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(request);
      } else {
        Promise.reject(err);
      }
    });
};
