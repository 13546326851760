import * as React from "react";
import { motion } from "framer-motion";
import MenuItems from "./MenuItems";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({toggle, isOpen}: any) => (
  <motion.ul className="flex flex-col gap-4 absolute top-24 px-4" variants={variants}>
    <MenuItems toggle={toggle} isOpen={isOpen} />
  </motion.ul>
);
