import { selectedLanguage } from "../../Localization/Localization";
import { addHtml } from "./ExampleOfResult";
import { combineHTML } from "./Questions";

interface IAddOnChoice {
  value: string;
  text: ILocale;
}
const Influence = (influence: any) => {
  const firstChoicesToBeSorted: IAddOnChoice[] = influence.addonServices.choices.filter((choice: IAddOnChoice) => {
    return choice.text.id !== "influence_addonServices_choices_21_text";
  });

  const lastElement: IAddOnChoice = influence.addonServices.choices.filter((choice: IAddOnChoice) => {
    return choice.text.id === "influence_addonServices_choices_21_text";
  })[0];

  const compareText = (s1: string, s2: string) => s1.localeCompare(s2);
  const getKeyValue =
    <U extends keyof T, T extends object>(key: U) =>
    (obj: T) =>
      obj[key];

  const sortedElements: IAddOnChoice[] = [...firstChoicesToBeSorted].sort((a: IAddOnChoice, b: IAddOnChoice) =>
    compareText(
      getKeyValue<keyof ILanguages, ILanguages>(selectedLanguage)(a.text),
      getKeyValue<keyof ILanguages, ILanguages>(selectedLanguage)(b.text)
    )
  );

  sortedElements.push(lastElement);

  return {
    name: "editPage",
    elements: [
      {
        type: "panel",
        name: "panelInfluence",
        renderMode: "list",
        title: influence.header,
        elements: [
          {
            type: "boolean",
            name: influence.sustainabilityGuidance.id,
            hideNumber: true,
            isRequired: true,
            title: influence.sustainabilityGuidance.title,
          },
          {
            type: "checkbox",
            name: influence.addonServices.id,
            title: influence.addonServices.title,
            hideNumber: true,
            choices: sortedElements,
          },
          {
            type: "html",
            name: "addonServicesMoreInfo",
            html: combineHTML([influence.addonServicesMoreInfo, addHtml(influence.addonServicesMoreInfoLink, "a")]),
            hideNumber: true,
          },
        ],
      },
      {
        type: "boolean",
        name: influence.riskAnalysis.id,
        hideNumber: true,
        isRequired: true,
        title: influence.riskAnalysis.title,
      },
      {
        type: "text",
        name: influence.other.id,
        hideNumber: true,
        title: influence.other.title,
      },
    ],
  };
};

export default Influence;
