import { selectedLanguage } from "../../Localization/Localization";
import { addHtml } from "./ExampleOfResult";

interface IMacroSector {
  title: ILocale;
  activities: ILocale[];
  id: string;
}

const makeIntoHref = (title: ILocale) => {
  const htmlString = `<a target=_blank rel=noreferrer href=${title.link} >` + title[selectedLanguage] + "</a>";
  return htmlString;
};

const Taxonomy = (taxonomy: any) => {
  const activitiesAsHtml = (activities: ILocale[]) => {
    const listItems = activities.map((activity: ILocale) => "<li>" + activity[selectedLanguage] + "</li>");

    let list = "<ul>";
    listItems.forEach((item) => (list += item));
    list += "</ul>";

    return list;
  };

  const renderMacroSectors = (): any[] => {
    return taxonomy.macroSectors.map((sector: IMacroSector) => {
      const sectorElement = {
        type: "checkbox",
        name: sector.id,
        title: makeIntoHref(sector.title),
        choices: [{ value: "relevant", text: " " }],
        hideNumber: true,
        popupdescription: activitiesAsHtml(sector.activities),
      };
      return sectorElement;
    });
  };

  const macroSectors: any[] = renderMacroSectors();

  const buildHTMLContent = () => {
    const hr = "<hr style=height:10px;visibility:hidden;border:none />";

    let htmlString = "";
    htmlString += "<p>" + taxonomy.contentPara1[selectedLanguage] + "</p>" + hr;
    htmlString +=
      "<p>" +
      taxonomy.contentPara2[selectedLanguage] +
      `<a href=${taxonomy.contentLink.link} target=_blank rel=noreferrer >` +
      taxonomy.contentLink[selectedLanguage] +
      "</a></p>"  + hr;
    htmlString += "<p>" + taxonomy.contentPara3[selectedLanguage] + "</p>"  + hr;
    htmlString += "<p>" + taxonomy.contentPara4[selectedLanguage] + "</p>";
    return htmlString;
  };

  return {
    elements: [
      {
        type: "html",
        name: "taxonomyHeader",
        html: addHtml(taxonomy.header, "h3"),
      },
      {
        type: "html",
        name: "taxonomyAbout",
        html: buildHTMLContent(),
      },
      ...macroSectors,
    ],
  };
};

export default Taxonomy;
