import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import showdown from "showdown";
import { Question, Serializer, StylesManager } from "survey-core";
import "survey-core/modern.min.css";
import "survey-core/survey.i18n";
import { Model, Survey, SurveyModel } from "survey-react-ui";

import { getMe, getSurveyResult } from "../../api/ApiService";
import { msalInstance } from "../../auth/msalConfig";
import CreateAnswerObject from "../../components/CreateAnswerObject";
import CustomButton from "../../components/CustomButton/CustomButton";
import Dictionary from "../../components/common/Dictionary";
import GapList from "../../components/GapList/GapList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NetworkError from "../../components/NetworkError/NetworkError";
import Questions from "../../components/Questions/Questions";
import Tags from "../../components/Tags";
import URL from "../../components/common/URL";
import ProgressBar from "../../components/common/ProgressBar";
import { useAppContext } from "../../context/SurveyContextProvider";
import { DecryptData, EncryptData } from "../../crypto/EncryptData";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import { SELECTED_SURVEY_FILTER, HAS_STARTED_A_SURVEY, YOUR_SURVEY_ID } from "../../data/Constants";
import styles from "./surveyState.module.css";

interface ISurveyUpdateQuestionOptions {
  question: IQuestion;
  cssClasses: IQuestionCssClasses;
}

interface IQuestion extends Question {
  popupdescription?: any;
}

interface INewSurvey {
  id: string;
}

const SurveyState: React.FC = () => {
  const navigate = useNavigate();

  const defaultThemeColors = StylesManager.ThemeColors["modern"];

  defaultThemeColors["$main-color"] = "#CFD3DB";
  defaultThemeColors["$main-hover-color"] = "#E9E6E0";
  defaultThemeColors["$answer-background-color"] = "#CFD3DB";
  StylesManager.applyTheme("modern");
  Serializer.addProperty("question", {
    name: "popupdescription:text",
    isLocalizable: true,
  });
  // Serializer.addProperty("itemValue", {
  //   name: "popupdescription:text",
  //   isLocalizable: true,
  // });
  const context = useAppContext();

  const prevFilter = window.localStorage.getItem(SELECTED_SURVEY_FILTER) || null;

  const survey = new Model(Questions(context, prevFilter ? JSON.parse(prevFilter) : []));

  const prevDataEncrypted = window.localStorage.getItem(YOUR_SURVEY_ID) || null;
  if (prevDataEncrypted) {
    const prevData = DecryptData(prevDataEncrypted);
    const data = JSON.parse(prevData);
    survey.data = data;
    if (data.pageNo !== undefined) {
      survey.currentPageNo = data.pageNo;
    }
  }

  const showDescription = (element: any) => {
    const descriptonTextElement = document.getElementById("questionDescriptionText");
    descriptonTextElement ? (descriptonTextElement.innerHTML = element.popupdescription) : null;
    const popupEl: any = document.getElementById("questionDescriptionPopup");
    const popupCloseBtn = document.getElementById("questionDescriptionPopupClose");
    popupCloseBtn
      ? (popupCloseBtn.onclick = () => {
          if (popupEl) {
            popupEl.close();
          }
        })
      : null;

    popupEl.showModal();
  };

  const [isCalculating, setIsCalculating] = useState(false);
  const [isError, setIsError] = useState(false);

  const [newActiveTag, setNewActiveTag] = useState("introduction");
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { t } = useTranslation();

  survey.requiredText = "";

  const getMarker = (marker: string): number => {
    if (survey) {
      const question = survey.getQuestionByName(marker);
      const page = survey.getPageByQuestion(question);
      return page.propertyHash.num - 1;
    } else {
      return 0;
    }
  };

  const envPageNum = getMarker("ENVIRONMENTAL_HEADER_TEXT");
  const socialPageNum = getMarker("SOCIAL_HEADER_TEXT");
  const socioPageNum = getMarker("SOCIO-ECONOMIC_HEADER_TEXT");
  const influencePageNum = survey.getPageByName("editPage").propertyHash.num - 1;

  const tags = ["introduction", "environment", "social", "socioEconomic", "influence"];
  const tagHandler = () => {
    if (survey.currentPageNo < envPageNum) {
      setNewActiveTag(tags[0]);
    } else if (survey.currentPageNo >= envPageNum && survey.currentPageNo < socialPageNum) {
      setNewActiveTag(tags[1]);
    } else if (survey.currentPageNo >= socialPageNum && survey.currentPageNo < socioPageNum) {
      setNewActiveTag(tags[2]);
    } else if (survey.currentPageNo >= socioPageNum && survey.currentPageNo < influencePageNum) {
      setNewActiveTag(tags[3]);
    } else if (survey.currentPageNo === influencePageNum) {
      setNewActiveTag(tags[4]);
    } else {
      setNewActiveTag(tags[5]);
    }
  };

  const toggleVisibility = (id: string, property: string, condition: string) => {
    const element: HTMLElement | null = document.getElementById(id);
    if (element) {
      if (property === "display") {
        element.style.display = condition;
      } else if (property === "justifyContent") {
        element.style.justifyContent = condition;
      }
    }
  };

  toggleVisibility("nextButton", "display", "inline");
  toggleVisibility("completeButton", "display", "none");
  toggleVisibility("previewMockBar", "display", "none");
  toggleVisibility("gapList", "display", survey.currentPageNo === influencePageNum ? "flex" : "none");
  toggleVisibility("influenceHeader", "display", survey.currentPageNo === influencePageNum ? "block" : "none");

  useEffect(() => {
    setCurrentPage(survey.currentPageNo);
    toggleVisibility("defaultBar", "display", "block");
  }, []);

  useEffect(() => {
    tagHandler();
  }, []);

  const sendResults = useCallback(
    async (sender: SurveyModel) => {
      setIsCalculating(true);
      const me = await getMe();
      const department = me.data.department;
      const answers = CreateAnswerObject(
        sender.data,
        context.value,
        msalInstance.getAllAccounts()[0].username,
        department
      );
      const getResults = async (): Promise<void> => {
        let newSurvey: INewSurvey = { id: "" };
        if (answers) {
          const res: any = await getSurveyResult(answers);
          if (res !== undefined && !res.message) {
            newSurvey = res.data;
            window.localStorage.removeItem(YOUR_SURVEY_ID);
            window.localStorage.removeItem(HAS_STARTED_A_SURVEY);
            setIsCalculating(false);
            navigate("/complete", { state: { id: newSurvey.id } });
          } else {
            setIsCalculating(false);
            setIsError(true);
          }
        }
      };
      getResults();
    },
    [navigate]
  );

  const saveSurveyData = () => {
    const surveyData = survey.data;
    surveyData.pageNo = survey.currentPageNo;

    const savedData = EncryptData(JSON.stringify(surveyData));
    window.localStorage.setItem(YOUR_SURVEY_ID, savedData);

    if (
      survey.getQuestionByName("skipIntroduction") !== undefined ||
      survey.getQuestionByName("skipIntroduction") === null
    ) {
      survey.getQuestionByName("skipIntroduction").clearValue();
      survey.getQuestionByName("skipIntroduction").isAnswered = false;
    }
  };

  // Create showdown mardown converter
  // To create links from text to html
  const converter = new showdown.Converter();
  converter.setOption("openLinksInNewWindow", true);
  survey.onTextMarkdown.add((_survey: Model, options: ISurveyMarkdownOptions) => {
    if (options.text.includes("http")) {
      let str = converter.makeHtml(options.text);

      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      options.html = str;
    }
  });

  const { width } = useWindowDimensions();
  const isMobile = width < 700;
  const isDictionaryScreen = width < 950;

  const myCss = {
    root: `sv-root-modern ${
      survey.currentPageNo === influencePageNum
        ? isDictionaryScreen
          ? styles.gapListMobile
          : styles.gapList
        : styles.defaultRootStyles
    }`,
    panel: {
      title: `sv-title sv-element__title sv-panel__title ${styles.titlePanel}`,
    },
  };

  survey.css = myCss;

  const prepareExampleQuestion = (name: string, value: string | string[] | boolean) => {
    const question = survey.getQuestionByName(name);
    question.isAnswered = true;
    question.value = value;
  };

  prepareExampleQuestion("example1", "reflect");
  prepareExampleQuestion("example3", "reflect");
  prepareExampleQuestion("exampleReflectCheckbox", ["positive", "negative"]);
  prepareExampleQuestion("exampleReflectRadiogroup", false);
  prepareExampleQuestion("exampleReflectComment", t("survey_exampleComment"));

  survey.onUpdatePanelCssClasses.add((_survey: Model, options: any) => {
    const classes = options.cssClasses;

    if (options.panel.propertyHash.name.includes("_action")) {
      classes.row += ` ${styles.reflectPanel}`;
      classes.panel.title += ` ${styles.reflectPanelTitle}`;
    }
    if (options.panel.propertyHash.name === "panelInfluence") {
      classes.panel.title += ` ${styles.influencePanelTitle}`;
    }
  });

  survey.onUpdatePageCssClasses.add((_survey: Model, options: any) => {
    const classes = options.cssClasses;
    if (
      options.page.elementsValue[0].propertyHash.name === "aboutHeader" ||
      (options.page.elementsValue.length > 1 && options.page.elementsValue[1].propertyHash.name === "exampleText") ||
      options.page.elementsValue[0].propertyHash.name.includes("headerText")
    ) {
      classes.row += ` ${styles.aboutHeaderPadding} `;
    }
  });

  survey.onUpdateQuestionCssClasses.add((_survey: Model, options: ISurveyUpdateQuestionOptions) => {
    const classes = options.cssClasses;

    if (options.question.popupdescription) {
      classes.mainRoot += ` ${styles.popupdescription} `;
    }

    if (options.question.propertyHash.name === "taxonomyHeader") {
      classes.mainRoot += ` ${styles.aboutHeaderElement} `;
    }

    if (options.question.propertyHash.name === "orientationImage") {
      classes.root += ` ${styles.orientationImage} `;
    }

    if (options.question.propertyHash.name === "aboutHeader") {
      classes.mainRoot += ` ${styles.aboutHeaderElement} `;
    }

    if (options.question.propertyHash.name === "example1" || options.question.propertyHash.name === "example3") {
      classes.itemChecked += ` ${styles.exampleQuestion} `;
    }

    if (options.question.propertyHash.name === "exampleReflectRadiogroup") {
      classes.title += ` ${styles.titleFinals} ${styles.fontWeight}`;
      classes.mainRoot += ` ${styles.finals}`;
      classes.label += ` ${styles.noTextWrap}`;
    }

    if (options.question.propertyHash.name.includes("Demo")) {
      classes.mainRoot += ` ${styles.fontWeight} `;
    }

    if (options.question.getType() === "imagepicker") {
      classes.item += ` ${width < 600 ? styles.imagePickerListMobile : styles.imagePickerList}`;
      classes.root += ` ${width < 600 ? styles.imagePickerContainerMobile : styles.imagePickerContainer}`;
    }

    if (options.question.getType() === "checkbox") {
      classes.comment += ` ${styles.commentField}`;
      classes.title += ` ${styles.questionTestWidth}`;

      if (options.question.propertyHash.choices.length === 1) {
        classes.mainRoot += ` ${styles.checkboxQuestions}`;
        classes.column += ` ${styles.checkboxColumn}`;
      } else {
        if (options.question.propertyHash.choices.length === 2) {
          classes.mainRoot += ` ${styles.finals}`;
        }
        classes.label += ` ${styles.noTextWrap}`;
      }
    }

    if (options.question.getType() === "radiogroup" && options.question.propertyHash.choices.length === 3) {
      const webStyles = options.question.propertyHash.name === "EstimatedBudget" ? styles.estBudget : styles.finals;
      const titleStyles = options.question.propertyHash.name.includes("_managed")
        ? ` ${styles.titleFinals} ${styles.fontWeight}`
        : ` ${styles.titleFinals}`;

      classes.mainRoot += ` ${isMobile ? styles.finalsMobile : webStyles}`;
      classes.title += titleStyles;
      classes.column += ` ${styles.options}`;
      classes.root += ` ${width < 1400 ? styles.optionsContainerMobile : styles.optionsContainer}`;
    }

    if (options.question.getType() === "comment") {
      classes.title += ` ${styles.fontWeight}`;
      classes.root += ` ${styles.boxSize}`;
    }

    if (options.question.getType() === "boolean") {
      classes.switch += ` ${styles.booleanOutline}`;
    }

    if (options.question.getType() === "html" && options.question.title === "questionReflectId") {
      classes.content += ` ${styles.questionReflectId}`;
    }
  });

  const mySurvey = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? -100 : 100,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: { delay: 0.05 }, // To remove jumpyness
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? -100 : 100,
        opacity: 0,
      };
    },
  };

  let doAnimantion = true;
  survey.onCurrentPageChanging.add(function (sender: any, options: any) {
    if (!doAnimantion) return;

    options.allowChanging = false;
    setTimeout(function () {
      doAnimantion = false;
      sender.currentPage = options.newCurrentPage;
      doAnimantion = true;
    }, 500);
    controls.start("exit");
  });

  survey.onCurrentPageChanged.add((_survey: any, options: any) => {
    controls.start("enter");
    controls.start("center");
    if (survey.state === "running") {
      tagHandler();
      setCurrentPage(survey.currentPageNo);
    }

    if (survey.state !== "completed") {
      saveSurveyData();
    }
    window.scrollTo(0, 0);
  });

  survey.onComplete.add((sender: SurveyModel) => {
    saveSurveyData();
    sendResults(sender);
  });

  const onCancelPreview = () => {
    setCurrentPage(influencePageNum);
    survey.cancelPreview(influencePageNum);
  };

  const onComplete = () => {
    survey.doComplete();
  };

  const tagElement = document.getElementById("tagsList");

  if (tagElement?.style.display === "none" && survey.state === "running") {
    tagHandler();
    tagElement.style.display = "flex";
  }

  survey.onShowingPreview.add((sender: any) => {
    toggleVisibility("nextButton", "display", "none");
    toggleVisibility("completeButton", "display", "inline");
    toggleVisibility("defaultBar", "display", "none");
    toggleVisibility("previewMockBar", "display", "block");
    toggleVisibility("gapList", "display", "none");
    toggleVisibility("influenceHeader", "display", "none");

    if (tagElement) {
      tagElement.style.display = "none";
    }

    const reviewCss = {
      root: `sv-root-modern`,
      panel: {
        title: `sv-title sv-element__title sv-panel__title ${styles.titlePanel}`,
      },
    };

    survey.css = reviewCss;

    const { example1, example3, exampleReflectCheckbox, exampleReflectRadiogroup, exampleReflectComment, ...data } =
      sender.data;
    survey.data = data;
  });
  const surveyStyles = isMobile ? styles.mobileContent : styles.surveyContent;

  survey.onAfterRenderQuestion.add(function (_survey: any, options: any) {
    //Do nothing if a question contains no description to show in a modal popup
    if (!options.question.popupdescription) return;
    //Create a 'Show Activities' button to invoke a modal popup
    const btn = document.createElement("button");
    btn.type = "button";
    btn.className = styles.dialogBoxBtn;
    btn.innerHTML = "Show activities"; // TODO
    const question = options.question;
    btn.onclick = function () {
      showDescription(question);
    };
    //Insert the created 'Show Activities' button into the rendered question's header
    let header = options.htmlElement.querySelector("h5");
    if (!header) header = options.htmlElement;
    header.appendChild(btn);

    options.question.propertyHash.choices &&
      options.question.propertyHash.choices.forEach((choice: any) => {
        if (choice.popupdescription !== undefined) {
          const btn = document.createElement("button");
          btn.type = "button";
          btn.className = styles.dialogBoxBtn;
          btn.innerHTML = "Show activities"; // TODO
          const question = options.question;
          btn.onclick = function () {
            showDescription(question);
          };

          const elements: any[] = Array.from(options.htmlElement.querySelectorAll("div"));

          let choiceElement: any = elements.filter((el: any) => el.innerText === choice.text)[0];
          if (!choiceElement) choiceElement = options.htmlElement;

          choiceElement.appendChild(btn);
        }
      });
  });

  survey.onValueChanging.add((sender: SurveyModel, options: any) => {
    // only for page taxonomy
    if (options.name.includes("MacroSector")) {
      const page = sender.getPageByQuestion(options.question);
      const elements = page.elements;
      elements.forEach((element: any) => {
        if (!element.propertyHash.name.includes(options.name)) {
          element.clearValue();
        }
      });
    }
  });

  const failedToComplete = () => (
    <>
      <NetworkError />
      <CustomButton
        text="Retry"
        classNames={styles.retryButtonStyles}
        onClick={() => {
          sendResults(survey);
        }}
      />
    </>
  );

  return (
    <>
      {isCalculating ? (
        <LoadingSpinner />
      ) : isError ? (
        failedToComplete()
      ) : (
        <>
          <div className={styles.infoBar}>
            <Tags selectedTag={newActiveTag} />
            {isDictionaryScreen ? <Dictionary /> : null}
          </div>
          <div className={styles.bodyPage}>
            {isDictionaryScreen ? null : <div className={styles.emptyDiv} />}
            <AnimatePresence initial={false}>
              <motion.div
                ref={mySurvey}
                className={surveyStyles}
                variants={variants}
                animate={controls}
                initial="enter"
                exit="exit"
              >
                <div className={isMobile ? styles.surveyBodyMobile : styles.surveyBody}>
                  <div id="influenceHeader" className="hidden px-2">
                    <h3>{t("survey", { context: "influenceHeader" })}</h3>
                    <p>
                      {t("survey", { context: "influenceExplainer" })}
                      <br />
                      <br />
                      {t("survey", { context: "contacts1" })}
                      <URL base="url" context="sustainabilityLink" text="sustainabilityText" />
                    </p>
                  </div>
                  <div
                    style={{ flexDirection: isDictionaryScreen ? "column" : "row" }}
                    className={styles.surveyGapContainer}
                  >
                    <GapList surveyData={survey.data} initialVisibility={"none"} />
                    <dialog id="questionDescriptionPopup" className={styles.dialogBox}>
                      <div className={styles.dialogBoxContent}>
                        <img
                          id="questionDescriptionPopupClose"
                          src="/cross.png"
                          height={"16px"}
                          width={"16px"}
                          className="mr-4 mt-4 flex cursor-pointer self-end hover:scale-110"
                          key="/cross.png"
                          alt="cross"
                        />
                        <div className={styles.dialogBoxHeader}>
                          <h3> {t("survey", { context: "macroSectorActivities" })}</h3>
                        </div>
                        <div className={styles.dialogBoxBody}>
                          <div id="questionDescriptionText"></div>
                        </div>
                      </div>
                    </dialog>
                    <Survey model={survey} />
                  </div>
                  <ProgressBar
                    id="defaultBar"
                    page={currentPage}
                    amountOfPages={survey.PageCount + 1}
                    marker1={envPageNum}
                    marker2={socialPageNum}
                    marker3={socioPageNum}
                    marker4={influencePageNum}
                  />
                  <ProgressBar
                    id="previewMockBar"
                    page={survey.PageCount + 1}
                    amountOfPages={survey.PageCount + 1}
                    marker1={envPageNum}
                    marker2={socialPageNum}
                    marker3={socioPageNum}
                    marker4={influencePageNum}
                  />
                  <div
                    id="navButtons"
                    style={{ justifyContent: !survey.isFirstPage ? "space-between" : "flex-end" }}
                    className={styles.navButtonsContainer}
                  >
                    <button
                      id="prevButton"
                      type="button"
                      className={styles.navButton}
                      style={{ display: !survey.isFirstPage ? "inline" : "none" }}
                      onClick={() => {
                        survey.state === "preview" || survey.state === "empty" ? onCancelPreview() : survey.prevPage();
                      }}
                    >
                      {t("survey", { context: "previous" })}
                    </button>

                    <button
                      id="nextButton"
                      type="button"
                      className={styles.navButton}
                      onClick={() => {
                        survey.isLastPage ? survey.showPreview() : survey.nextPage();
                      }}
                    >
                      {survey.state === "preview"
                        ? t("survey", { context: "complete" })
                        : survey.isLastPage
                        ? t("survey", { context: "preview" })
                        : t("survey", { context: "next" })}
                    </button>
                    <button id="completeButton" type="button" className={styles.inVisNavButton} onClick={onComplete}>
                      {t("survey", { context: "complete" })}
                    </button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
            {isDictionaryScreen ? null : <Dictionary />}
          </div>
        </>
      )}
    </>
  );
};

export default SurveyState;
