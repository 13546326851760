import React from "react";
import { useTranslation } from "react-i18next";

const Tags: React.FC<ITagsProps> = ({ selectedTag }) => {
  const tags = ["introduction", "environment", "social", "socioEconomic", "influence"];

  const { t } = useTranslation();
  return (
    <div id="tagsList" className="mt-8 flex w-full flex-wrap items-center justify-center gap-3">
      {tags.map((tag, index) => (
        <p
          key={tag}
          className={`w-[150px] border p-2 text-center text-black ${
            selectedTag === tag ? "border-primary bg-primary" : "border-black bg-white"
          }`}
          id={`tag${index + 1}`}
        >
          {t("symbols", { context: tag })}
        </p>
      ))}
    </div>
  );
};

export default Tags;
