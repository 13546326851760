import { motion } from "framer-motion";
import Html2Pdf from "js-html2pdf";
import React from "react";
import ReactToPrint from "react-to-print";
import { Cross } from "../../assets";
import ModalBackdrop from "../common/ModalBackdrop";
import PDFSaver from "../PDFSaver/PDFSaver";
import styles from "./Modal.module.css";
import PrimaryButton from "../common/PrimaryButton";

interface IModalProps {
  handleClose: () => void;
  answers: any;
  client: string;
  resultRef: any;
  answersRef: any;
}

const Modal: React.FC<IModalProps> = ({ handleClose, answers, client, resultRef, answersRef }) => {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  const styleIfElementPresent = (result: HTMLDivElement, id: string, classNames: string[]) => {
    const element = result.querySelector(id);
    if (element) {
      classNames.forEach((style) => {
        if (id === "#legends") {
          element.classList.add(styles[style]);
        } else {
          element.className += styles[style];
        }
      });
    }
  };
  const setPageBodyAndNumber = (result: HTMLDivElement, pageNumber: number) => {
    const bodyId = "#body" + pageNumber;
    const pageId = "#page" + pageNumber;
    styleIfElementPresent(result, bodyId, ["pageBody"]);
    styleIfElementPresent(result, pageId, ["pageBody", "visible"]);
  };

  const printResult = (): ((target: HTMLIFrameElement) => Promise<any>) | undefined => {
    return async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const result = document.getElementsByTagName("div")[0];
        result.className += styles.pdfStyles;

        styleIfElementPresent(result, "#recommendations", ["marker"]);
        styleIfElementPresent(result, "#potentials", ["marker"]);
        styleIfElementPresent(result, "#potentials2", ["marker"]);
        styleIfElementPresent(result, "#header", ["visible"]);
        styleIfElementPresent(result, "#pdfcontents", ["visible"]);
        styleIfElementPresent(result, "#body1", ["initialPageBody"]);
        styleIfElementPresent(result, "#legends", ["legendsPdf"]);

        const images = result.querySelectorAll("#image");
        images.forEach((image) => {
          // placebo style. doesn't exist but makes other styling work
          image.className += styles.imagePdf;
        });

        for (let i = 2; i < 9; i++) {
          setPageBodyAndNumber(result, i);
        }

        const options = {
          filename: `SustainabilityRelectionTool_${client}_Result.pdf`,
        };
        const exporter = new Html2Pdf(result, options);
        await exporter.getPdf(true);
      }
    };
  };

  return (
    <ModalBackdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="fixed flex h-[150px] w-[30%] min-w-[250px] max-w-[400px] flex-col items-center rounded-xl bg-greige p-4 shadow-lg"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <img
          src={Cross}
          height={"16px"}
          width={"16px"}
          className="cursor-pointer self-end"
          key="/cross.png"
          alt="cross"
          onClick={handleClose}
        />
        <div className="flex h-full w-full flex-row items-center justify-evenly">
          <ReactToPrint
            trigger={() => <PrimaryButton onClick={() => null} text="pdf_result" />}
            content={() => resultRef.current}
            removeAfterPrint={true}
            print={printResult()}
          />
          <PDFSaver answers={answers} client={client} />
        </div>
      </motion.div>
    </ModalBackdrop>
  );
};

export default Modal;
