import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SurveyState from "./SurveyState";
import { HAS_STARTED_A_SURVEY } from "../../data/Constants";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useAppContext } from "../../context/SurveyContextProvider";

const SurveyWrapper: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const context = useAppContext();

  useEffect(() => {
    const storedSurveyVersion = window.localStorage.getItem(HAS_STARTED_A_SURVEY);

    if (!storedSurveyVersion) {
      navigate("/");
    }

    if (context.value.formVersion !== 0) {
      setIsLoading(false);
    }
  }, [context.value.formVersion]);

  return isLoading ? <LoadingSpinner /> : <SurveyState />;
};

export default SurveyWrapper;
