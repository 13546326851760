import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "./PrimaryButton";

const Dictionary: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const dictionaryContent = () => (
    <div
      className={`absolute right-0 top-4 z-50 mx-4 my-16 flex min-w-[90%] flex-col items-start gap-1 rounded-lg bg-greige p-2 shadow-md lg:mx-12 lg:min-w-[850px] xl:min-w-[1200px]`}
    >
      <h4 className="mt-2 text-lg font-medium">{t("dictionary", { context: "header" })}</h4>
      <p>{t("dictionary", { context: "environmental" })}</p>
      <p>{t("dictionary", { context: "social" })}</p>
      <p>{t("dictionary", { context: "socio" })}</p>
      <p>{t("dictionary", { context: "relevant" })}</p>
      <p>{t("dictionary", { context: "longTerm" })}</p>
      <h4 className="mt-2 text-lg  font-medium">{t("dictionary", { context: "headerPosNeg" })}</h4>
      <p>{t("dictionary", { context: "positive" })}</p>
      <p>{t("dictionary", { context: "negative" })}</p>
      <p>{t("dictionary", { context: "both" })}</p>
      <h4 className="mt-2 text-lg  font-medium">{t("dictionary", { context: "headerManagement" })}</h4>
      <p>{t("dictionary", { context: "reflect" })}</p>
      <p>{t("dictionary", { context: "influence" })}</p>
    </div>
  );

  return (
    <div className="relative mt-8 flex w-full flex-col items-center lg:w-[15%]">
      <PrimaryButton text="dictionary_buttonText" onClick={() => setIsOpen(!isOpen)} isSecondary fontSize="text-base" />
      {isOpen ? dictionaryContent() : null}
    </div>
  );
};

export default Dictionary;
