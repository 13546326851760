import { addHtml } from "./ExampleOfResult";

const AboutProject = (aboutProject: any) => {
  return [
    {
      elements: [
        {
          type: "html",
          html: addHtml(aboutProject.header, "h3"),
        },
        {
          name: aboutProject.SrtIsPerformedOn && aboutProject.SrtIsPerformedOn.id,
          title: aboutProject.SrtIsPerformedOn && aboutProject.SrtIsPerformedOn.title,
          type: "radiogroup",
          choices: aboutProject.SrtIsPerformedOn && aboutProject.SrtIsPerformedOn.choices,
          hideNumber: true,
          isRequired: true,
        },
        {
          name: aboutProject.client.id,
          title: aboutProject.client.title,
          type: "text",
          hideNumber: true,
        },
        {
          name: aboutProject.projectDescription.id,
          title: aboutProject.projectDescription.title,
          type: "text",
          hideNumber: true,
        },
        {
          type: "html",
          html: addHtml(aboutProject.consultantNamesDescription, "h5"),
        },
        {
          name: aboutProject.consultantNames.id,
          title: aboutProject.consultantNames.title,
          type: "text",
          hideNumber: true,
        },
        {
          name: aboutProject.consultantRoles.id,
          title: aboutProject.consultantRoles.title,
          type: "checkbox",
          hasOther: true,
          hideNumber: true,
          colCount: 1,
          choices: aboutProject.consultantRoles.roles,
          otherText: aboutProject.consultantRoles.otherRole, // role-other potential porblem
          // otherItem: { value: "tset", text: "woop" }, // get it to change its value/text
        },
        {
          type: "html",
          html: aboutProject.description,
        },
      ],
    },
    {
      elements: [
        {
          name: aboutProject.estimatedAfryBudget.id,
          type: "radiogroup",
          title: aboutProject.estimatedAfryBudget.title,
          hideNumber: true,
          colCount: 1,
          choices: aboutProject.estimatedAfryBudget.choices,
          hasDescription: true,
          descriptionLocation: "underTitle",
          description: aboutProject.estimatedAfryBudget.description,
        },
        {
          name: aboutProject.sustainabilityStrategiesOrGoals.id,
          title: aboutProject.sustainabilityStrategiesOrGoals.title,
          type: "checkbox",
          hasNone: false,
          hideNumber: true,
          hasOther: false,
          colCount: 1,
          choices: aboutProject.sustainabilityStrategiesOrGoals.choices,
        },
        {
          type: "html",
          html: addHtml(aboutProject.completionInformation, "em"),
        },
        {
          type: "html",
          html: aboutProject.sustainabilityStrategiesOrGoals.description,
        },
      ],
    },
  ];
};

export default AboutProject;
