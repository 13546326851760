import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Welcome } from "../assets";
import ChangeLanguageButton from "../components/common/ChangeLanguageButton";
import URL from "../components/common/URL";
import useWindowDimensions from "../customHooks/useWindowsDimensions";
import { SELECTED_SURVEY_FILTER, HAS_STARTED_A_SURVEY } from "../data/Constants";

const baseFilters: ISurveyFilter[] = [
  {
    id: "1",
    name: "SRT Full version",
    filter: [],
  },
  {
    id: "2",
    name: "BU Buildings Narrow scope version",
    filter: [
      // environment
      "AspectChoiceOfEnergy",
      "AspectHighEnergy",
      "AspectFossilEnergy",
      "AspectCarbonSink",
      "AspectChangeOfEcosystem",
      "AspectSVHCSubquestion",
      "AspectEmissionsFromCombustion",
      "AspectDischargeOfHazardousSubstances",
      "AspectWaterTreatment",
      "AspectProtectedWater",
      "AspectOtherWater",
      "AspectMassTransportation",
      "AspectContaminationOfLand",
      "AspectLeakageHazardousSubstances",
      "AspectOtherGround",
      "AspectProtectedNaturalHabitats",
      "AspectWildlife",
      "AspectEcosystems",
      "AspectEndageredSpecies",
      "AspectUndevelopedAreaFarmland",
      "AspectAreaCompetingInterests",
      "AspectOtherNature",
      "AspectPublicTransportation",
      "AspectPedestrian",
      "AspectBicyclePaths",
      "AspectOtherSustainableMobility",

      // social
      "AspectPrivateProperties",
      "AspectHumanRightRiskViolation",
      "AspectOtherWorkingConditions",
      "AspectSocietalCohesion",
      "AspectUrbanAndRural",
      "AspectOtherIdentityCohesion",
      "AspectStakeholders",
      "AspectLocalCommunity",
      "AspectOtherCommunityStakeholder",
      "AspectAccessRecreationalAreas",
      "AspectSocietalRisks",
      "AspectEverydayService",

      // socio
      "AspectProjectCorruptionRisk",
      "AspectLackAntiCorruption",
      "AspectSalaries",
      "AspectOtherAntiCorruption",
      "AspectJobOpportunities",
      "AspectConstructingCommunities",
      "AspectConstructingInfrastructure",
      "AspectOtherSocioInvestment",
      "AspectLowCostApartmenst",
      "AspectHousingAccommodation",
      "AspectLocalBusinessContent",
      "AspectOtherEconomicSustainableGrowth",
      "AspectJobsWeakAreas",
    ],
  },
];

const SelectSurvey: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [surveyFilters, setSurveyFilters] = useState<ISurveyFilter[]>(baseFilters);

  const navigateToSurvey = () => {
    window.localStorage.setItem(HAS_STARTED_A_SURVEY, JSON.stringify(true));
    navigate("/survey");
  };

  useEffect(() => {
    // fetch Filters setSurveyFilters
    const filter = surveyFilters.find((surv) => surv.name === selectedFilter);
    window.localStorage.setItem(SELECTED_SURVEY_FILTER, JSON.stringify(filter ? filter.filter : []));
  }, [selectedFilter]);

  useEffect(() => {
    const prevDataEncrypted = window.localStorage.getItem(HAS_STARTED_A_SURVEY) || null;

    if (prevDataEncrypted) {
      navigateToSurvey();
    }
  }, []);

  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center tracking-wide">
      <div className="mb-2 flex h-full w-full max-w-[1100px] flex-col gap-3 px-6 py-4">
        <div className="relative mt-6 ">
          <div className="h-fit bg-gray-300 bg-welcomeVerySmall bg-cover bg-center">
            <img
              loading="lazy"
              onLoad={() => setImageHasLoaded(true)}
              alt="welcomeImage"
              src={Welcome}
              className="block aspect-auto h-auto w-full min-w-[300px] object-cover object-center "
            />
          </div>
          <div
            className={`absolute bottom-0 w-full bg-black bg-opacity-50 py-4
          opacity-0 transition-opacity duration-300 ${imageHasLoaded && "opacity-100"}
          `}
          >
            <h3
              className={`m-0 px-6 text-center text-white `}
              style={{
                fontFamily: "Campton-Light",
                fontSize: width > 800 ? 28 : width > 500 ? 20 : 16,
              }}
            >
              Welcome to AFRY’s Sustainability Reflection Tool for Infrastructure sales and projects
            </h3>
          </div>
        </div>
        <div className="my-4">
          <ChangeLanguageButton />
        </div>
        <p>{t("introduction_welcomePage", { context: "content1" })}</p>
        <p>{t("introduction_welcomePage", { context: "content2" })}</p>
        <p>
          {t("introduction_welcomePage", { context: "content3Start" })}
          <URL base="url" text="sustainabilityConsultantText" context="sustainabilityConsultantLink" />
          {t("introduction_welcomePage", { context: "content3End" })}
        </p>
        <p>{t("introduction_welcomePage", { context: "content4" })}</p>
        <div className="mt-3 flex items-center justify-start gap-12">
          <div className="flex flex-col gap-1">
            <label htmlFor="changeSurvey">Select Survey</label>
            <select
              name="changeSurvey"
              id="changeSurvey"
              className="min-w-[160px] rounded-sm border border-black "
              defaultValue={surveyFilters[0].name}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              {surveyFilters.map((filter: ISurveyFilter) => (
                <option key={filter.id} value={filter.name}>
                  {filter.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4 flex items-center justify-start">
          <button
            className="rounded-md bg-primary px-8 py-2 text-xl tracking-wide shadow-md 
          transition-all duration-300 ease-in-out hover:scale-110"
            onClick={() => navigateToSurvey()}
          >
            {t("start")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectSurvey;
