import React, { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { getAppLocales, getQuestionnaire } from "../../api/ApiService";
import Header from "../../components/Header/Header";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NetworkError from "../../components/NetworkError/NetworkError";
import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute";
import { BASE_LOCALE, useAppContext } from "../../context/SurveyContextProvider";
import { DecryptData, EncryptData } from "../../crypto/EncryptData";
import { ADMIN_GROUP, QUESTIONNAIRE, QUESTIONNAIRE_VERSION } from "../../data/Constants";
import CompletedSurvey from "../CompletedSurvey/CompletedSurvey";
import InfoPage from "../InfoPage";
import SelectSurvey from "../SelectSurvey";
import SurveyList from "../SurveyList";
import SurveyWrapper from "../SurveyState/SurveyWrapper";
import i18n, { shortLocaleNames } from "../../Localization/Localization";

const AuthenticatedNavigation: React.FC = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const context = useAppContext();

  const fetchQuestionnaire = async (): Promise<any> => {
    const response = await getQuestionnaire();

    if (response.data !== undefined && Object.entries(response.data).length > 1) {
      context.addSurveyPages(response.data);
      window.localStorage.setItem(QUESTIONNAIRE, EncryptData(JSON.stringify(response.data)));
    } else {
      setIsError(true);
    }
    setIsFetching(false);
  };

  const fetchAppLocales = async (): Promise<any> => {
    const fetchedAppLocales = await getAppLocales();

    const getLocaleShortName = (key: string) => {
      const foundLocale = shortLocaleNames.find((locale) => locale.key === key);
      return foundLocale ? foundLocale.value : "";
    };

    if (fetchedAppLocales.data !== undefined && Object.entries(fetchedAppLocales.data).length > 1) {
      const newAppLocales: any[] = [];

      Object.entries(fetchedAppLocales.data).map((entry: any) => {
        const lang = entry[0] === "english" ? "default" : getLocaleShortName(entry[0]);
        const translations = entry[1].translation;

        Object.entries(translations).forEach((entry: any) => {
          const newObj: ILocale = { ...BASE_LOCALE, [lang]: entry[1], id: entry[0] };
          const doesExistIndex = newAppLocales.findIndex((locale) => locale.id === entry[0]);

          if (doesExistIndex > -1) {
            newAppLocales[doesExistIndex][lang] = entry[1];
          } else {
            newAppLocales.push(newObj);
          }
        });
      });
      context.updateLocales(newAppLocales);

      Object.entries(fetchedAppLocales.data).forEach((entry: any) => {
        i18n.addResourceBundle(getLocaleShortName(entry[0]), "translation", entry[1].translation);
      });
    } else {
      setIsError(true);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (context.locals.length === 0) {
      fetchAppLocales();
      context.updateLocale(i18n.language);
    }
  }, []);

  useEffect(() => {
    const questionnaire = window.localStorage.getItem(QUESTIONNAIRE);

    if (questionnaire !== null) {
      const prevData = DecryptData(questionnaire);
      const data = JSON.parse(prevData);

      if (data.formVersion === QUESTIONNAIRE_VERSION) {
        context.addSurveyPages(data);
        setIsFetching(false);
      } else {
        fetchQuestionnaire();
      }
    } else {
      fetchQuestionnaire();
    }
  }, []);

  const content = isError ? <NetworkError /> : <SelectSurvey />;

  return (
    <div className="scrollbar flex flex-col items-center justify-center bg-white">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={isFetching ? <LoadingSpinner /> : content} />
          <Route path="/survey" element={<SurveyWrapper />} />
          <Route path="/complete" element={<CompletedSurvey />} />
          <Route path="/information" element={<InfoPage />} />
          <Route
            path="/surveyList"
            element={
              <ProtectedRoute isForLink={false} validAzureAdGroupId={ADMIN_GROUP}>
                <SurveyList />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/formBuilder"
            element={
              <ProtectedRoute isForLink={false} validAzureAdGroupId={ADMIN_GROUP}>
                <FormBuilder />
              </ProtectedRoute>
            }
          /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AuthenticatedNavigation;
