import React from "react";
import { useTranslation } from "react-i18next";

const NetworkError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
      <h2 style={{ marginBlockEnd: 0, color: "#ce5757" }}>{t("networkError")}</h2>
      <p style={{whiteSpace: "pre-line"}}>
        {t("networkError", { context: "message" })}
      </p>
    </div>
  );
};

export default NetworkError;
