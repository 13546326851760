import React from "react";
import { HorizontalAlign, IDocOptions, SurveyHelper, SurveyPDF } from "survey-pdf";
import { AFRY_Logotype_Horizontal_Explainer } from "../../assets";
import { useAppContext } from "../../context/SurveyContextProvider";
import AboutProject from "../Questions/AboutProject";
import Influence from "../Questions/Influence";
import { reflectionHeader, setAffectedAreasSection, setSection } from "../Questions/Questions";
import PrimaryButton from "../common/PrimaryButton";
import styles from "./PDFSaver.module.css";

interface IPrint {
  answers: any;
  client: string;
}

interface ISectionProps {
  elements: any[];
}

const PDFSaver: React.FC<IPrint> = ({ answers, client }) => {
  const context = useAppContext();

  const savePdf = (data: any) => {
    const disclaimer = {
      type: "html",
      html: "<h4>This is a temporary format during soft launch</h4>",
    };
    const introduction = AboutProject(context.value.introduction.aboutProject);
    introduction[0].elements.unshift(disclaimer);
    const environment = setSection(context.value.environment, context.value.utils.questionChoices);
    const evironmentReflection = setAffectedAreasSection(
      context.value.environment,
      reflectionHeader(context.value.utils.sectionReflection),
      context.value.utils.questionChoices,
      context.value.utils.sectionReflection
    );
    const social = setSection(context.value.social, context.value.utils.questionChoices);
    const socialReflection = setAffectedAreasSection(
      context.value.social,
      reflectionHeader(context.value.utils.sectionReflection),
      context.value.utils.questionChoices,
      context.value.utils.sectionReflection
    );
    const socio = setSection(context.value.socioEconomic, context.value.utils.questionChoices);
    const socioReflection = setAffectedAreasSection(
      context.value.socioEconomic,
      reflectionHeader(context.value.utils.sectionReflection),
      context.value.utils.questionChoices,
      context.value.utils.sectionReflection
    );
    const influence = Influence(context.value.influence);

    // hack to fix special chars
    introduction.forEach((e) => {
      e.elements.forEach((obj) => {
        if (obj.choices !== undefined) {
          obj.choices.forEach((choice: any) => {
            if (typeof choice.text === "string") {
              if (choice.text.includes("&#60;")) {
                choice.text = choice.text.replace("&#60;", "<");
              }
              if (choice.text.includes("&gt;")) {
                choice.text = choice.text.replace("&gt;", ">");
              }
            }
          });
        }
      });
    });

    environment.forEach((element) => {
      element.elements.forEach((obj) => {
        if (typeof obj.title === "string" && obj.title.includes("href")) {
          const start = obj.title.split("<");
          const part1 = start[0];
          const part2 = start[1].split(">")[1];
          const part3 = start[2].split("/a>")[1];
          const part4 = start[3].split(">")[1];

          obj.title = part1 + part2 + part3 + part4;
        }
      });
    });

    const removeHtml = (section: ISectionProps[]) => {
      section.forEach((element) => {
        element.elements.forEach((obj) => {
          if (typeof obj.title === "string" && obj.title.includes("span")) {
            const start = obj.title.split("<br><span>");
            const part1 = start[0];
            const part2 = start[1].split("</span>")[0];

            obj.title = part1 + part2;
          }
        });
      });
    };

    removeHtml(social);
    removeHtml(socio);

    const jsonData = {
      pages: [
        ...introduction,
        ...environment,
        evironmentReflection,
        ...social,
        socialReflection,
        ...socio,
        socioReflection,
        influence,
      ],
    };

    const surveyOptions: IDocOptions = {
      fontSize: 14,
      htmlRenderAs: "standard",
    };

    const surveyPdf = new SurveyPDF(jsonData, surveyOptions);
    surveyPdf.data = data;
    surveyPdf.mode = "display";
    const myCSS = {
      text: `sv_q_text_root ${styles.surveyBody}`,
    };
    surveyPdf.css = myCSS;
    SurveyHelper.TEXT_COLOR = "black";

    surveyPdf.onRenderHeader.add(function (survey, canvas) {
      canvas.drawImage({
        base64: AFRY_Logotype_Horizontal_Explainer,
        width: 90,
        horizontalAlign: HorizontalAlign.Left,
        margins: {
          left: 10,
          top: 5,
          bot: 5,
        },
      });
    });

    surveyPdf.onRenderFooter.add(function (survey, canvas) {
      canvas.drawText({
        text: canvas.pageNumber + "/" + canvas.countPages,
        horizontalAlign: HorizontalAlign.Right,
        margins: {
          right: 10,
          bot: 5,
        },
      });
    });

    surveyPdf.save("Sustainability_Reflection_Tool_" + client + "_Answers.pdf");
  };

  return <PrimaryButton text="pdf_answers" onClick={() => savePdf(answers)} isSecondary />;
};

export default PDFSaver;
