import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRecommendations, getSurvey } from "../../api/ApiService";

import CompletedContent from "../../components/CompletedContent/CompletedContent";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useWindowDimensions from "../../customHooks/useWindowsDimensions";
import { YOUR_SURVEY_ID } from "../../data/Constants";
import styles from "./CompletedSurvey.module.css";
import { useAppContext } from "../../context/SurveyContextProvider";

interface IState {
  id: string;
}

const CompletedSurvey: React.FC = () => {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(true);
  const [buttonText, setButtonText] = useState<string>("evaluateNewProject");
  const context = useAppContext();
  const { width } = useWindowDimensions();

  const location = useLocation();
  const state = location.state as IState;

  const surveyData = window.localStorage[YOUR_SURVEY_ID];

  const [survey, setSurvey] = useState<ISurvey>();
  const [allRecommendations, setAllRecommendations] = useState<ILocale[]>([]);

  useEffect(() => {
    const fetchSurvey = async (): Promise<void> => {
      const surveyResponse = await getSurvey(state.id);

      if (surveyResponse !== undefined) {
        setSurvey(surveyResponse.data);
      }

      if (context.recommendations.length === 0) {
        const recommendationsResponse = await getRecommendations();

        if (recommendationsResponse !== undefined) {
          setAllRecommendations(recommendationsResponse.data);
          context.updateRecommendations(recommendationsResponse.data);
        }
      }
      setIsFetching(false);
    };

    fetchSurvey();
    if (surveyData) {
      setButtonText("continueSurvey");
    }
  }, [surveyData]);

  const isMobile = width < 1000 ? styles.mobileCompletedScreen : styles.completedScreen;
  return (
    <div className={isMobile}>
      {isFetching ? (
        <div className={styles.spinnerWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {survey === undefined ? (
            <p>{t("apiError")}</p>
          ) : (
            <CompletedContent
              date={survey.datePerformed}
              surveyResult={survey.surveyResult}
              answers={survey.surveyAnswers}
              buttonText={buttonText}
              allRecommendations={allRecommendations}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CompletedSurvey;
