import React from "react";

interface IProgressProps {
  id: string;
  page: number;
  amountOfPages: number;
  marker1: number;
  marker2: number;
  marker3: number;
  marker4: number;
}

const ProgressBar: React.FC<IProgressProps> = ({ id, page, amountOfPages, marker1, marker2, marker3, marker4 }) => {
  const convertToPercentage = (page: number): string => {
    const width = (page / amountOfPages) * 100;

    return width.toPrecision(2) + "%";
  };

  return (
    <div id={id} className="relative mb-4 mt-8 h-2 w-full bg-neutral-100 hidden">
      <div style={{ width: convertToPercentage(page) }} className="h-2 bg-primary" />
      <div style={{ left: convertToPercentage(marker1) }} className="absolute -top-[100%] h-5 w-[1px] bg-black" />
      <div style={{ left: convertToPercentage(marker2) }} className="absolute -top-[100%] h-5 w-[1px] bg-black" />
      <div style={{ left: convertToPercentage(marker3) }} className="absolute -top-[100%] h-5 w-[1px] bg-black" />
      <div style={{ left: convertToPercentage(marker4) }} className="absolute -top-[100%] h-5 w-[1px] bg-black" />
    </div>
  );
};

export default ProgressBar;
