import { motion } from "framer-motion";
import React from "react";

interface IBackdrop {
  children: any;
  onClick: () => void;
}

const ModalBackdrop: React.FC<IBackdrop> = ({ children, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      className="fixed z-40 inset-0 h-full w-full flex items-center justify-center bg-black bg-opacity-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default ModalBackdrop;
