import React from "react";
import { motion } from "framer-motion";
import styles from "./LoadingSpinner.module.css";

const LoadingSpinner: React.FC = () => {
  const spinTransition = {
    rotate: {
      repeat: Infinity,
      ease: "linear",
      duration: 1,
    },
  };

  return (
    <div className={styles.containerStyle}>
      <motion.span className={styles.circleStyle} animate={{ rotate: 360 }} transition={spinTransition} />
    </div>
  );
};

export default LoadingSpinner;
